export type Maybe<T> = T | null

export interface DateQueryOperatorInput {
  eq?: Maybe<Date>

  ne?: Maybe<Date>

  gt?: Maybe<Date>

  gte?: Maybe<Date>

  lt?: Maybe<Date>

  lte?: Maybe<Date>

  in?: Maybe<(Maybe<Date>)[]>

  nin?: Maybe<(Maybe<Date>)[]>
}

export interface FloatQueryOperatorInput {
  eq?: Maybe<number>

  ne?: Maybe<number>

  gt?: Maybe<number>

  gte?: Maybe<number>

  lt?: Maybe<number>

  lte?: Maybe<number>

  in?: Maybe<(Maybe<number>)[]>

  nin?: Maybe<(Maybe<number>)[]>
}

export interface StringQueryOperatorInput {
  eq?: Maybe<string>

  ne?: Maybe<string>

  in?: Maybe<(Maybe<string>)[]>

  nin?: Maybe<(Maybe<string>)[]>

  regex?: Maybe<string>

  glob?: Maybe<string>
}

export interface IntQueryOperatorInput {
  eq?: Maybe<number>

  ne?: Maybe<number>

  gt?: Maybe<number>

  gte?: Maybe<number>

  lt?: Maybe<number>

  lte?: Maybe<number>

  in?: Maybe<(Maybe<number>)[]>

  nin?: Maybe<(Maybe<number>)[]>
}

export interface ImageSharpFilterInput {
  fixed?: Maybe<ImageSharpFixedFilterInput>

  resolutions?: Maybe<ImageSharpResolutionsFilterInput>

  fluid?: Maybe<ImageSharpFluidFilterInput>

  sizes?: Maybe<ImageSharpSizesFilterInput>

  original?: Maybe<ImageSharpOriginalFilterInput>

  resize?: Maybe<ImageSharpResizeFilterInput>

  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface ImageSharpFixedFilterInput {
  base64?: Maybe<StringQueryOperatorInput>

  tracedSVG?: Maybe<StringQueryOperatorInput>

  aspectRatio?: Maybe<FloatQueryOperatorInput>

  width?: Maybe<FloatQueryOperatorInput>

  height?: Maybe<FloatQueryOperatorInput>

  src?: Maybe<StringQueryOperatorInput>

  srcSet?: Maybe<StringQueryOperatorInput>

  srcWebp?: Maybe<StringQueryOperatorInput>

  srcSetWebp?: Maybe<StringQueryOperatorInput>

  originalName?: Maybe<StringQueryOperatorInput>
}

export interface ImageSharpResolutionsFilterInput {
  base64?: Maybe<StringQueryOperatorInput>

  tracedSVG?: Maybe<StringQueryOperatorInput>

  aspectRatio?: Maybe<FloatQueryOperatorInput>

  width?: Maybe<FloatQueryOperatorInput>

  height?: Maybe<FloatQueryOperatorInput>

  src?: Maybe<StringQueryOperatorInput>

  srcSet?: Maybe<StringQueryOperatorInput>

  srcWebp?: Maybe<StringQueryOperatorInput>

  srcSetWebp?: Maybe<StringQueryOperatorInput>

  originalName?: Maybe<StringQueryOperatorInput>
}

export interface ImageSharpFluidFilterInput {
  base64?: Maybe<StringQueryOperatorInput>

  tracedSVG?: Maybe<StringQueryOperatorInput>

  aspectRatio?: Maybe<FloatQueryOperatorInput>

  src?: Maybe<StringQueryOperatorInput>

  srcSet?: Maybe<StringQueryOperatorInput>

  srcWebp?: Maybe<StringQueryOperatorInput>

  srcSetWebp?: Maybe<StringQueryOperatorInput>

  sizes?: Maybe<StringQueryOperatorInput>

  originalImg?: Maybe<StringQueryOperatorInput>

  originalName?: Maybe<StringQueryOperatorInput>

  presentationWidth?: Maybe<IntQueryOperatorInput>

  presentationHeight?: Maybe<IntQueryOperatorInput>
}

export interface ImageSharpSizesFilterInput {
  base64?: Maybe<StringQueryOperatorInput>

  tracedSVG?: Maybe<StringQueryOperatorInput>

  aspectRatio?: Maybe<FloatQueryOperatorInput>

  src?: Maybe<StringQueryOperatorInput>

  srcSet?: Maybe<StringQueryOperatorInput>

  srcWebp?: Maybe<StringQueryOperatorInput>

  srcSetWebp?: Maybe<StringQueryOperatorInput>

  sizes?: Maybe<StringQueryOperatorInput>

  originalImg?: Maybe<StringQueryOperatorInput>

  originalName?: Maybe<StringQueryOperatorInput>

  presentationWidth?: Maybe<IntQueryOperatorInput>

  presentationHeight?: Maybe<IntQueryOperatorInput>
}

export interface ImageSharpOriginalFilterInput {
  width?: Maybe<FloatQueryOperatorInput>

  height?: Maybe<FloatQueryOperatorInput>

  src?: Maybe<StringQueryOperatorInput>
}

export interface ImageSharpResizeFilterInput {
  src?: Maybe<StringQueryOperatorInput>

  tracedSVG?: Maybe<StringQueryOperatorInput>

  width?: Maybe<IntQueryOperatorInput>

  height?: Maybe<IntQueryOperatorInput>

  aspectRatio?: Maybe<FloatQueryOperatorInput>

  originalName?: Maybe<StringQueryOperatorInput>
}

export interface NodeFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface NodeFilterListInput {
  elemMatch?: Maybe<NodeFilterInput>
}

export interface InternalFilterInput {
  content?: Maybe<StringQueryOperatorInput>

  contentDigest?: Maybe<StringQueryOperatorInput>

  description?: Maybe<StringQueryOperatorInput>

  fieldOwners?: Maybe<StringQueryOperatorInput>

  ignoreType?: Maybe<BooleanQueryOperatorInput>

  mediaType?: Maybe<StringQueryOperatorInput>

  owner?: Maybe<StringQueryOperatorInput>

  type?: Maybe<StringQueryOperatorInput>
}

export interface BooleanQueryOperatorInput {
  eq?: Maybe<boolean>

  ne?: Maybe<boolean>

  in?: Maybe<(Maybe<boolean>)[]>

  nin?: Maybe<(Maybe<boolean>)[]>
}

export interface AnswerYamlFilterListInput {
  elemMatch?: Maybe<AnswerYamlFilterInput>
}

export interface AnswerYamlFilterInput {
  ignore?: Maybe<BooleanQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  cardId?: Maybe<StringQueryOperatorInput>

  ask?: Maybe<StringQueryOperatorInput>

  contents?: Maybe<AnswerYamlContentFilterListInput>

  actions?: Maybe<AnswerYamlActionsFilterInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface AnswerYamlContentFilterListInput {
  elemMatch?: Maybe<AnswerYamlContentFilterInput>
}

export interface AnswerYamlContentFilterInput {
  text?: Maybe<StringQueryOperatorInput>

  contentFormat?: Maybe<StringQueryOperatorInput>

  image?: Maybe<StringQueryOperatorInput>
}

export interface AnswerYamlActionsFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  image?: Maybe<StringQueryOperatorInput>

  actions?: Maybe<AnswerYamlActionsActionFilterListInput>
}

export interface AnswerYamlActionsActionFilterListInput {
  elemMatch?: Maybe<AnswerYamlActionsActionFilterInput>
}

export interface AnswerYamlActionsActionFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  label?: Maybe<StringQueryOperatorInput>

  external?: Maybe<BooleanQueryOperatorInput>

  actionId?: Maybe<StringQueryOperatorInput>
}

export interface CategoryYamlFilterListInput {
  elemMatch?: Maybe<CategoryYamlFilterInput>
}

export interface CategoryYamlFilterInput {
  ignore?: Maybe<BooleanQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  sub?: Maybe<CategoryYamlSubFilterListInput>

  tags?: Maybe<StringQueryOperatorInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface CategoryYamlSubFilterListInput {
  elemMatch?: Maybe<CategoryYamlSubFilterInput>
}

export interface CategoryYamlSubFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<StringQueryOperatorInput>

  tags?: Maybe<StringQueryOperatorInput>
}

export interface TagYamlFilterListInput {
  elemMatch?: Maybe<TagYamlFilterInput>
}

export interface TagYamlFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  answers?: Maybe<StringQueryOperatorInput>

  relations?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface SettingYamlFilterListInput {
  elemMatch?: Maybe<SettingYamlFilterInput>
}

export interface SettingYamlFilterInput {
  botId?: Maybe<StringQueryOperatorInput>

  customContents?: Maybe<SettingYamlCustomContentFilterListInput>

  deploymentHostName?: Maybe<StringQueryOperatorInput>

  description?: Maybe<StringQueryOperatorInput>

  enabled?: Maybe<BooleanQueryOperatorInput>

  excludeDuplication?: Maybe<BooleanQueryOperatorInput>

  excludes?: Maybe<SettingYamlExcludeFilterListInput>

  facebook?: Maybe<StringQueryOperatorInput>

  fallbackUrl?: Maybe<StringQueryOperatorInput>

  faviconUrl?: Maybe<StringQueryOperatorInput>

  footer?: Maybe<StringQueryOperatorInput>

  gtags?: Maybe<StringQueryOperatorInput>

  header?: Maybe<StringQueryOperatorInput>

  headerImageAltText?: Maybe<StringQueryOperatorInput>

  headerImageLinkUrl?: Maybe<StringQueryOperatorInput>

  headerImageUrl?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  karakuriWebRootUrl?: Maybe<StringQueryOperatorInput>

  language?: Maybe<StringQueryOperatorInput>

  layoutLeft?: Maybe<LayoutFilterListInput>

  layoutMain?: Maybe<LayoutFilterListInput>

  layoutRight?: Maybe<LayoutFilterListInput>

  tagLayoutLeft?: Maybe<LayoutFilterListInput>

  tagLayoutMain?: Maybe<LayoutFilterListInput>

  tagLayoutRight?: Maybe<LayoutFilterListInput>

  answerLayoutLeft?: Maybe<LayoutFilterListInput>

  answerLayoutMain?: Maybe<LayoutFilterListInput>

  answerLayoutRight?: Maybe<LayoutFilterListInput>

  nrOfDisplay?: Maybe<IntQueryOperatorInput>

  pvTrackingId?: Maybe<StringQueryOperatorInput>

  pvViewId?: Maybe<StringQueryOperatorInput>

  questionnaire?: Maybe<SettingYamlQuestionnaireFilterInput>

  questionnaireTextNo?: Maybe<StringQueryOperatorInput>

  questionnaireTextYes?: Maybe<StringQueryOperatorInput>

  scripts?: Maybe<StringQueryOperatorInput>

  styles?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  twitter?: Maybe<StringQueryOperatorInput>

  url?: Maybe<StringQueryOperatorInput>

  tagCloud?: Maybe<BooleanQueryOperatorInput>

  categories?: Maybe<SettingYamlCategoriesFilterListInput>

  excludeFromFAQ?: Maybe<SettingYamlExcludeFromFaqFilterListInput>

  deploy?: Maybe<BooleanQueryOperatorInput>

  informations?: Maybe<SettingYamlInformationsFilterListInput>

  importants?: Maybe<SettingYamlImportantsFilterListInput>

  layout?: Maybe<StringQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface SettingYamlCustomContentFilterListInput {
  elemMatch?: Maybe<SettingYamlCustomContentFilterInput>
}

export interface SettingYamlCustomContentFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  type?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  parts?: Maybe<SettingYamlCustomContentPartFilterListInput>
}

export interface SettingYamlCustomContentPartFilterListInput {
  elemMatch?: Maybe<SettingYamlCustomContentPartFilterInput>
}

export interface SettingYamlCustomContentPartFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  image?: Maybe<StringQueryOperatorInput>
}

export interface SettingYamlExcludeFilterListInput {
  elemMatch?: Maybe<SettingYamlExcludeFilterInput>
}

export interface SettingYamlExcludeFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>
}

export interface LayoutFilterListInput {
  elemMatch?: Maybe<LayoutFilterInput>
}

export interface LayoutFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  type?: Maybe<LayoutTypeQueryOperatorInput>

  icons?: Maybe<LayoutIconFilterListInput>
}

export interface LayoutTypeQueryOperatorInput {
  eq?: Maybe<LayoutType>

  ne?: Maybe<LayoutType>

  in?: Maybe<(Maybe<LayoutType>)[]>

  nin?: Maybe<(Maybe<LayoutType>)[]>
}

export interface LayoutIconFilterListInput {
  elemMatch?: Maybe<LayoutIconFilterInput>
}

export interface LayoutIconFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  reference?: Maybe<StringQueryOperatorInput>

  image?: Maybe<StringQueryOperatorInput>
}

export interface SettingYamlQuestionnaireFilterInput {
  yes?: Maybe<StringQueryOperatorInput>

  no?: Maybe<StringQueryOperatorInput>
}

export interface SettingYamlCategoriesFilterListInput {
  elemMatch?: Maybe<SettingYamlCategoriesFilterInput>
}

export interface SettingYamlCategoriesFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  sub?: Maybe<SettingYamlCategoriesSubFilterListInput>

  tags?: Maybe<StringQueryOperatorInput>
}

export interface SettingYamlCategoriesSubFilterListInput {
  elemMatch?: Maybe<SettingYamlCategoriesSubFilterInput>
}

export interface SettingYamlCategoriesSubFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<StringQueryOperatorInput>

  tags?: Maybe<StringQueryOperatorInput>
}

export interface SettingYamlExcludeFromFaqFilterListInput {
  elemMatch?: Maybe<SettingYamlExcludeFromFaqFilterInput>
}

export interface SettingYamlExcludeFromFaqFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>
}

export interface SettingYamlInformationsFilterListInput {
  elemMatch?: Maybe<SettingYamlInformationsFilterInput>
}

export interface SettingYamlInformationsFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>
}

export interface SettingYamlImportantsFilterListInput {
  elemMatch?: Maybe<SettingYamlImportantsFilterInput>
}

export interface SettingYamlImportantsFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>
}

export interface DuotoneGradient {
  highlight: string

  shadow: string

  opacity?: Maybe<number>
}

export interface Potrace {
  turnPolicy?: Maybe<PotraceTurnPolicy>

  turdSize?: Maybe<number>

  alphaMax?: Maybe<number>

  optCurve?: Maybe<boolean>

  optTolerance?: Maybe<number>

  threshold?: Maybe<number>

  blackOnWhite?: Maybe<boolean>

  color?: Maybe<string>

  background?: Maybe<string>
}

export interface FileFilterInput {
  birthtime?: Maybe<DateQueryOperatorInput>

  birthtimeMs?: Maybe<FloatQueryOperatorInput>

  sourceInstanceName?: Maybe<StringQueryOperatorInput>

  absolutePath?: Maybe<StringQueryOperatorInput>

  relativePath?: Maybe<StringQueryOperatorInput>

  extension?: Maybe<StringQueryOperatorInput>

  size?: Maybe<IntQueryOperatorInput>

  prettySize?: Maybe<StringQueryOperatorInput>

  modifiedTime?: Maybe<DateQueryOperatorInput>

  accessTime?: Maybe<DateQueryOperatorInput>

  changeTime?: Maybe<DateQueryOperatorInput>

  birthTime?: Maybe<DateQueryOperatorInput>

  root?: Maybe<StringQueryOperatorInput>

  dir?: Maybe<StringQueryOperatorInput>

  base?: Maybe<StringQueryOperatorInput>

  ext?: Maybe<StringQueryOperatorInput>

  name?: Maybe<StringQueryOperatorInput>

  relativeDirectory?: Maybe<StringQueryOperatorInput>

  dev?: Maybe<IntQueryOperatorInput>

  mode?: Maybe<IntQueryOperatorInput>

  nlink?: Maybe<IntQueryOperatorInput>

  uid?: Maybe<IntQueryOperatorInput>

  gid?: Maybe<IntQueryOperatorInput>

  rdev?: Maybe<IntQueryOperatorInput>

  blksize?: Maybe<IntQueryOperatorInput>

  ino?: Maybe<IntQueryOperatorInput>

  blocks?: Maybe<IntQueryOperatorInput>

  atimeMs?: Maybe<FloatQueryOperatorInput>

  mtimeMs?: Maybe<FloatQueryOperatorInput>

  ctimeMs?: Maybe<FloatQueryOperatorInput>

  atime?: Maybe<DateQueryOperatorInput>

  mtime?: Maybe<DateQueryOperatorInput>

  ctime?: Maybe<DateQueryOperatorInput>

  publicURL?: Maybe<StringQueryOperatorInput>

  childImageSharp?: Maybe<ImageSharpFilterInput>

  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  childrenAnswerYaml?: Maybe<AnswerYamlFilterListInput>

  childrenCategoryYaml?: Maybe<CategoryYamlFilterListInput>

  childrenTagYaml?: Maybe<TagYamlFilterListInput>

  childrenSettingYaml?: Maybe<SettingYamlFilterListInput>
}

export interface FileSortInput {
  fields?: Maybe<(Maybe<FileFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface ImageSharpSortInput {
  fields?: Maybe<(Maybe<ImageSharpFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface AnswerYamlSortInput {
  fields?: Maybe<(Maybe<AnswerYamlFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface CategoryYamlSortInput {
  fields?: Maybe<(Maybe<CategoryYamlFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface ImportantYamlFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface ImportantYamlSortInput {
  fields?: Maybe<(Maybe<ImportantYamlFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface InformationYamlFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}

export interface InformationYamlSortInput {
  fields?: Maybe<(Maybe<InformationYamlFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface SettingYamlSortInput {
  fields?: Maybe<(Maybe<SettingYamlFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface TagYamlSortInput {
  fields?: Maybe<(Maybe<TagYamlFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface SitePageContextFilterInput {
  parentId?: Maybe<StringQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  parentTitle?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  taggedAnswers?: Maybe<StringQueryOperatorInput>

  parentHasAnswers?: Maybe<BooleanQueryOperatorInput>

  ask?: Maybe<StringQueryOperatorInput>

  answerId?: Maybe<StringQueryOperatorInput>

  cardId?: Maybe<StringQueryOperatorInput>

  answers?: Maybe<SitePageContextAnswersFilterListInput>

  operatorEscalationKeyword?: Maybe<StringQueryOperatorInput>

  karakuriWebRootUrl?: Maybe<StringQueryOperatorInput>

  info?: Maybe<BooleanQueryOperatorInput>

  relatedCategories?: Maybe<SitePageContextRelatedCategoriesFilterListInput>
}

export interface SitePageContextAnswersFilterListInput {
  elemMatch?: Maybe<SitePageContextAnswersFilterInput>
}

export interface SitePageContextAnswersFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  cardId?: Maybe<StringQueryOperatorInput>

  ask?: Maybe<StringQueryOperatorInput>

  contents?: Maybe<SitePageContextAnswersContentsFilterListInput>

  actions?: Maybe<SitePageContextAnswersActionsFilterInput>
}

export interface SitePageContextAnswersContentsFilterListInput {
  elemMatch?: Maybe<SitePageContextAnswersContentsFilterInput>
}

export interface SitePageContextAnswersContentsFilterInput {
  text?: Maybe<StringQueryOperatorInput>

  contentFormat?: Maybe<StringQueryOperatorInput>

  image?: Maybe<StringQueryOperatorInput>
}

export interface SitePageContextAnswersActionsFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  actions?: Maybe<SitePageContextAnswersActionsActionsFilterListInput>
}

export interface SitePageContextAnswersActionsActionsFilterListInput {
  elemMatch?: Maybe<SitePageContextAnswersActionsActionsFilterInput>
}

export interface SitePageContextAnswersActionsActionsFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  label?: Maybe<StringQueryOperatorInput>

  external?: Maybe<BooleanQueryOperatorInput>

  actionId?: Maybe<StringQueryOperatorInput>
}

export interface SitePageContextRelatedCategoriesFilterListInput {
  elemMatch?: Maybe<SitePageContextRelatedCategoriesFilterInput>
}

export interface SitePageContextRelatedCategoriesFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  sub?: Maybe<SitePageContextRelatedCategoriesSubFilterListInput>

  parent?: Maybe<StringQueryOperatorInput>
}

export interface SitePageContextRelatedCategoriesSubFilterListInput {
  elemMatch?: Maybe<SitePageContextRelatedCategoriesSubFilterInput>
}

export interface SitePageContextRelatedCategoriesSubFilterInput {
  parent?: Maybe<StringQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>
}

export interface SitePluginFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  resolve?: Maybe<StringQueryOperatorInput>

  name?: Maybe<StringQueryOperatorInput>

  version?: Maybe<StringQueryOperatorInput>

  pluginOptions?: Maybe<SitePluginPluginOptionsFilterInput>

  nodeAPIs?: Maybe<StringQueryOperatorInput>

  browserAPIs?: Maybe<StringQueryOperatorInput>

  ssrAPIs?: Maybe<StringQueryOperatorInput>

  pluginFilepath?: Maybe<StringQueryOperatorInput>

  packageJson?: Maybe<SitePluginPackageJsonFilterInput>
}

export interface SitePluginPluginOptionsFilterInput {
  features?: Maybe<StringQueryOperatorInput>

  name?: Maybe<StringQueryOperatorInput>

  path?: Maybe<StringQueryOperatorInput>

  stylesProvider?: Maybe<SitePluginPluginOptionsStylesProviderFilterInput>

  fonts?: Maybe<SitePluginPluginOptionsFontsFilterInput>

  modules?: Maybe<StringQueryOperatorInput>

  endpointUrl?: Maybe<StringQueryOperatorInput>

  pathCheck?: Maybe<BooleanQueryOperatorInput>
}

export interface SitePluginPluginOptionsStylesProviderFilterInput {
  injectFirst?: Maybe<BooleanQueryOperatorInput>
}

export interface SitePluginPluginOptionsFontsFilterInput {
  google?: Maybe<SitePluginPluginOptionsFontsGoogleFilterListInput>
}

export interface SitePluginPluginOptionsFontsGoogleFilterListInput {
  elemMatch?: Maybe<SitePluginPluginOptionsFontsGoogleFilterInput>
}

export interface SitePluginPluginOptionsFontsGoogleFilterInput {
  family?: Maybe<StringQueryOperatorInput>

  variants?: Maybe<StringQueryOperatorInput>
}

export interface SitePluginPackageJsonFilterInput {
  name?: Maybe<StringQueryOperatorInput>

  description?: Maybe<StringQueryOperatorInput>

  version?: Maybe<StringQueryOperatorInput>

  main?: Maybe<StringQueryOperatorInput>

  author?: Maybe<StringQueryOperatorInput>

  license?: Maybe<StringQueryOperatorInput>

  dependencies?: Maybe<SitePluginPackageJsonDependenciesFilterListInput>

  devDependencies?: Maybe<SitePluginPackageJsonDevDependenciesFilterListInput>

  peerDependencies?: Maybe<SitePluginPackageJsonPeerDependenciesFilterListInput>

  keywords?: Maybe<StringQueryOperatorInput>
}

export interface SitePluginPackageJsonDependenciesFilterListInput {
  elemMatch?: Maybe<SitePluginPackageJsonDependenciesFilterInput>
}

export interface SitePluginPackageJsonDependenciesFilterInput {
  name?: Maybe<StringQueryOperatorInput>

  version?: Maybe<StringQueryOperatorInput>
}

export interface SitePluginPackageJsonDevDependenciesFilterListInput {
  elemMatch?: Maybe<SitePluginPackageJsonDevDependenciesFilterInput>
}

export interface SitePluginPackageJsonDevDependenciesFilterInput {
  name?: Maybe<StringQueryOperatorInput>

  version?: Maybe<StringQueryOperatorInput>
}

export interface SitePluginPackageJsonPeerDependenciesFilterListInput {
  elemMatch?: Maybe<SitePluginPackageJsonPeerDependenciesFilterInput>
}

export interface SitePluginPackageJsonPeerDependenciesFilterInput {
  name?: Maybe<StringQueryOperatorInput>

  version?: Maybe<StringQueryOperatorInput>
}

export interface SitePageFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  path?: Maybe<StringQueryOperatorInput>

  internalComponentName?: Maybe<StringQueryOperatorInput>

  component?: Maybe<StringQueryOperatorInput>

  componentChunkName?: Maybe<StringQueryOperatorInput>

  isCreatedByStatefulCreatePages?: Maybe<BooleanQueryOperatorInput>

  context?: Maybe<SitePageContextFilterInput>

  pluginCreator?: Maybe<SitePluginFilterInput>

  pluginCreatorId?: Maybe<StringQueryOperatorInput>

  componentPath?: Maybe<StringQueryOperatorInput>
}

export interface SitePageSortInput {
  fields?: Maybe<(Maybe<SitePageFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface SitePluginSortInput {
  fields?: Maybe<(Maybe<SitePluginFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface SiteSiteMetadataFilterInput {
  title?: Maybe<StringQueryOperatorInput>

  description?: Maybe<StringQueryOperatorInput>
}

export interface SiteFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  siteMetadata?: Maybe<SiteSiteMetadataFilterInput>

  port?: Maybe<IntQueryOperatorInput>

  host?: Maybe<StringQueryOperatorInput>

  polyfill?: Maybe<BooleanQueryOperatorInput>

  pathPrefix?: Maybe<StringQueryOperatorInput>

  buildTime?: Maybe<DateQueryOperatorInput>
}

export interface SiteSortInput {
  fields?: Maybe<(Maybe<SiteFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export interface DirectoryFilterInput {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  sourceInstanceName?: Maybe<StringQueryOperatorInput>

  absolutePath?: Maybe<StringQueryOperatorInput>

  relativePath?: Maybe<StringQueryOperatorInput>

  extension?: Maybe<StringQueryOperatorInput>

  size?: Maybe<IntQueryOperatorInput>

  prettySize?: Maybe<StringQueryOperatorInput>

  modifiedTime?: Maybe<DateQueryOperatorInput>

  accessTime?: Maybe<DateQueryOperatorInput>

  changeTime?: Maybe<DateQueryOperatorInput>

  birthTime?: Maybe<DateQueryOperatorInput>

  root?: Maybe<StringQueryOperatorInput>

  dir?: Maybe<StringQueryOperatorInput>

  base?: Maybe<StringQueryOperatorInput>

  ext?: Maybe<StringQueryOperatorInput>

  name?: Maybe<StringQueryOperatorInput>

  relativeDirectory?: Maybe<StringQueryOperatorInput>

  dev?: Maybe<IntQueryOperatorInput>

  mode?: Maybe<IntQueryOperatorInput>

  nlink?: Maybe<IntQueryOperatorInput>

  uid?: Maybe<IntQueryOperatorInput>

  gid?: Maybe<IntQueryOperatorInput>

  rdev?: Maybe<IntQueryOperatorInput>

  blksize?: Maybe<IntQueryOperatorInput>

  ino?: Maybe<IntQueryOperatorInput>

  blocks?: Maybe<IntQueryOperatorInput>

  atimeMs?: Maybe<FloatQueryOperatorInput>

  mtimeMs?: Maybe<FloatQueryOperatorInput>

  ctimeMs?: Maybe<FloatQueryOperatorInput>

  birthtimeMs?: Maybe<FloatQueryOperatorInput>

  atime?: Maybe<DateQueryOperatorInput>

  mtime?: Maybe<DateQueryOperatorInput>

  ctime?: Maybe<DateQueryOperatorInput>

  birthtime?: Maybe<DateQueryOperatorInput>
}

export interface DirectorySortInput {
  fields?: Maybe<(Maybe<DirectoryFieldsEnum>)[]>

  order?: (Maybe<SortOrderEnum>)[]
}

export enum LayoutType {
  Category = 'category',
  Icon = 'icon',
  Information = 'information',
  Important = 'important',
  Frequent = 'frequent',
  Search = 'search',
  Navi = 'navi',
  Tag = 'tag'
}

export enum PotraceTurnPolicy {
  TurnpolicyBlack = 'TURNPOLICY_BLACK',
  TurnpolicyWhite = 'TURNPOLICY_WHITE',
  TurnpolicyLeft = 'TURNPOLICY_LEFT',
  TurnpolicyRight = 'TURNPOLICY_RIGHT',
  TurnpolicyMinority = 'TURNPOLICY_MINORITY',
  TurnpolicyMajority = 'TURNPOLICY_MAJORITY'
}

export enum ImageFormat {
  NoChange = 'NO_CHANGE',
  Jpg = 'JPG',
  Png = 'PNG',
  Webp = 'WEBP'
}

export enum ImageCropFocus {
  Center = 'CENTER',
  North = 'NORTH',
  Northeast = 'NORTHEAST',
  East = 'EAST',
  Southeast = 'SOUTHEAST',
  South = 'SOUTH',
  Southwest = 'SOUTHWEST',
  West = 'WEST',
  Northwest = 'NORTHWEST',
  Entropy = 'ENTROPY',
  Attention = 'ATTENTION'
}

export enum ImageFit {
  Cover = 'COVER',
  Contain = 'CONTAIN',
  Fill = 'FILL'
}

export enum FileFieldsEnum {
  Birthtime = 'birthtime',
  BirthtimeMs = 'birthtimeMs',
  SourceInstanceName = 'sourceInstanceName',
  AbsolutePath = 'absolutePath',
  RelativePath = 'relativePath',
  Extension = 'extension',
  Size = 'size',
  PrettySize = 'prettySize',
  ModifiedTime = 'modifiedTime',
  AccessTime = 'accessTime',
  ChangeTime = 'changeTime',
  BirthTime = 'birthTime',
  Root = 'root',
  Dir = 'dir',
  Base = 'base',
  Ext = 'ext',
  Name = 'name',
  RelativeDirectory = 'relativeDirectory',
  Dev = 'dev',
  Mode = 'mode',
  Nlink = 'nlink',
  Uid = 'uid',
  Gid = 'gid',
  Rdev = 'rdev',
  Blksize = 'blksize',
  Ino = 'ino',
  Blocks = 'blocks',
  AtimeMs = 'atimeMs',
  MtimeMs = 'mtimeMs',
  CtimeMs = 'ctimeMs',
  Atime = 'atime',
  Mtime = 'mtime',
  Ctime = 'ctime',
  PublicUrl = 'publicURL',
  ChildImageSharpFixedBase64 = 'childImageSharp___fixed___base64',
  ChildImageSharpFixedTracedSvg = 'childImageSharp___fixed___tracedSVG',
  ChildImageSharpFixedAspectRatio = 'childImageSharp___fixed___aspectRatio',
  ChildImageSharpFixedWidth = 'childImageSharp___fixed___width',
  ChildImageSharpFixedHeight = 'childImageSharp___fixed___height',
  ChildImageSharpFixedSrc = 'childImageSharp___fixed___src',
  ChildImageSharpFixedSrcSet = 'childImageSharp___fixed___srcSet',
  ChildImageSharpFixedSrcWebp = 'childImageSharp___fixed___srcWebp',
  ChildImageSharpFixedSrcSetWebp = 'childImageSharp___fixed___srcSetWebp',
  ChildImageSharpFixedOriginalName = 'childImageSharp___fixed___originalName',
  ChildImageSharpResolutionsBase64 = 'childImageSharp___resolutions___base64',
  ChildImageSharpResolutionsTracedSvg = 'childImageSharp___resolutions___tracedSVG',
  ChildImageSharpResolutionsAspectRatio = 'childImageSharp___resolutions___aspectRatio',
  ChildImageSharpResolutionsWidth = 'childImageSharp___resolutions___width',
  ChildImageSharpResolutionsHeight = 'childImageSharp___resolutions___height',
  ChildImageSharpResolutionsSrc = 'childImageSharp___resolutions___src',
  ChildImageSharpResolutionsSrcSet = 'childImageSharp___resolutions___srcSet',
  ChildImageSharpResolutionsSrcWebp = 'childImageSharp___resolutions___srcWebp',
  ChildImageSharpResolutionsSrcSetWebp = 'childImageSharp___resolutions___srcSetWebp',
  ChildImageSharpResolutionsOriginalName = 'childImageSharp___resolutions___originalName',
  ChildImageSharpFluidBase64 = 'childImageSharp___fluid___base64',
  ChildImageSharpFluidTracedSvg = 'childImageSharp___fluid___tracedSVG',
  ChildImageSharpFluidAspectRatio = 'childImageSharp___fluid___aspectRatio',
  ChildImageSharpFluidSrc = 'childImageSharp___fluid___src',
  ChildImageSharpFluidSrcSet = 'childImageSharp___fluid___srcSet',
  ChildImageSharpFluidSrcWebp = 'childImageSharp___fluid___srcWebp',
  ChildImageSharpFluidSrcSetWebp = 'childImageSharp___fluid___srcSetWebp',
  ChildImageSharpFluidSizes = 'childImageSharp___fluid___sizes',
  ChildImageSharpFluidOriginalImg = 'childImageSharp___fluid___originalImg',
  ChildImageSharpFluidOriginalName = 'childImageSharp___fluid___originalName',
  ChildImageSharpFluidPresentationWidth = 'childImageSharp___fluid___presentationWidth',
  ChildImageSharpFluidPresentationHeight = 'childImageSharp___fluid___presentationHeight',
  ChildImageSharpSizesBase64 = 'childImageSharp___sizes___base64',
  ChildImageSharpSizesTracedSvg = 'childImageSharp___sizes___tracedSVG',
  ChildImageSharpSizesAspectRatio = 'childImageSharp___sizes___aspectRatio',
  ChildImageSharpSizesSrc = 'childImageSharp___sizes___src',
  ChildImageSharpSizesSrcSet = 'childImageSharp___sizes___srcSet',
  ChildImageSharpSizesSrcWebp = 'childImageSharp___sizes___srcWebp',
  ChildImageSharpSizesSrcSetWebp = 'childImageSharp___sizes___srcSetWebp',
  ChildImageSharpSizesSizes = 'childImageSharp___sizes___sizes',
  ChildImageSharpSizesOriginalImg = 'childImageSharp___sizes___originalImg',
  ChildImageSharpSizesOriginalName = 'childImageSharp___sizes___originalName',
  ChildImageSharpSizesPresentationWidth = 'childImageSharp___sizes___presentationWidth',
  ChildImageSharpSizesPresentationHeight = 'childImageSharp___sizes___presentationHeight',
  ChildImageSharpOriginalWidth = 'childImageSharp___original___width',
  ChildImageSharpOriginalHeight = 'childImageSharp___original___height',
  ChildImageSharpOriginalSrc = 'childImageSharp___original___src',
  ChildImageSharpResizeSrc = 'childImageSharp___resize___src',
  ChildImageSharpResizeTracedSvg = 'childImageSharp___resize___tracedSVG',
  ChildImageSharpResizeWidth = 'childImageSharp___resize___width',
  ChildImageSharpResizeHeight = 'childImageSharp___resize___height',
  ChildImageSharpResizeAspectRatio = 'childImageSharp___resize___aspectRatio',
  ChildImageSharpResizeOriginalName = 'childImageSharp___resize___originalName',
  ChildImageSharpId = 'childImageSharp___id',
  ChildImageSharpParentId = 'childImageSharp___parent___id',
  ChildImageSharpParentParentId = 'childImageSharp___parent___parent___id',
  ChildImageSharpParentParentChildren = 'childImageSharp___parent___parent___children',
  ChildImageSharpParentChildren = 'childImageSharp___parent___children',
  ChildImageSharpParentChildrenId = 'childImageSharp___parent___children___id',
  ChildImageSharpParentChildrenChildren = 'childImageSharp___parent___children___children',
  ChildImageSharpParentInternalContent = 'childImageSharp___parent___internal___content',
  ChildImageSharpParentInternalContentDigest = 'childImageSharp___parent___internal___contentDigest',
  ChildImageSharpParentInternalDescription = 'childImageSharp___parent___internal___description',
  ChildImageSharpParentInternalFieldOwners = 'childImageSharp___parent___internal___fieldOwners',
  ChildImageSharpParentInternalIgnoreType = 'childImageSharp___parent___internal___ignoreType',
  ChildImageSharpParentInternalMediaType = 'childImageSharp___parent___internal___mediaType',
  ChildImageSharpParentInternalOwner = 'childImageSharp___parent___internal___owner',
  ChildImageSharpParentInternalType = 'childImageSharp___parent___internal___type',
  ChildImageSharpChildren = 'childImageSharp___children',
  ChildImageSharpChildrenId = 'childImageSharp___children___id',
  ChildImageSharpChildrenParentId = 'childImageSharp___children___parent___id',
  ChildImageSharpChildrenParentChildren = 'childImageSharp___children___parent___children',
  ChildImageSharpChildrenChildren = 'childImageSharp___children___children',
  ChildImageSharpChildrenChildrenId = 'childImageSharp___children___children___id',
  ChildImageSharpChildrenChildrenChildren = 'childImageSharp___children___children___children',
  ChildImageSharpChildrenInternalContent = 'childImageSharp___children___internal___content',
  ChildImageSharpChildrenInternalContentDigest = 'childImageSharp___children___internal___contentDigest',
  ChildImageSharpChildrenInternalDescription = 'childImageSharp___children___internal___description',
  ChildImageSharpChildrenInternalFieldOwners = 'childImageSharp___children___internal___fieldOwners',
  ChildImageSharpChildrenInternalIgnoreType = 'childImageSharp___children___internal___ignoreType',
  ChildImageSharpChildrenInternalMediaType = 'childImageSharp___children___internal___mediaType',
  ChildImageSharpChildrenInternalOwner = 'childImageSharp___children___internal___owner',
  ChildImageSharpChildrenInternalType = 'childImageSharp___children___internal___type',
  ChildImageSharpInternalContent = 'childImageSharp___internal___content',
  ChildImageSharpInternalContentDigest = 'childImageSharp___internal___contentDigest',
  ChildImageSharpInternalDescription = 'childImageSharp___internal___description',
  ChildImageSharpInternalFieldOwners = 'childImageSharp___internal___fieldOwners',
  ChildImageSharpInternalIgnoreType = 'childImageSharp___internal___ignoreType',
  ChildImageSharpInternalMediaType = 'childImageSharp___internal___mediaType',
  ChildImageSharpInternalOwner = 'childImageSharp___internal___owner',
  ChildImageSharpInternalType = 'childImageSharp___internal___type',
  Id = 'id',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type',
  ChildrenAnswerYaml = 'childrenAnswerYaml',
  ChildrenAnswerYamlIgnore = 'childrenAnswerYaml___ignore',
  ChildrenAnswerYamlId = 'childrenAnswerYaml___id',
  ChildrenAnswerYamlCardId = 'childrenAnswerYaml___cardId',
  ChildrenAnswerYamlAsk = 'childrenAnswerYaml___ask',
  ChildrenAnswerYamlContents = 'childrenAnswerYaml___contents',
  ChildrenAnswerYamlContentsText = 'childrenAnswerYaml___contents___text',
  ChildrenAnswerYamlContentsContentFormat = 'childrenAnswerYaml___contents___contentFormat',
  ChildrenAnswerYamlContentsImage = 'childrenAnswerYaml___contents___image',
  ChildrenAnswerYamlActionsId = 'childrenAnswerYaml___actions___id',
  ChildrenAnswerYamlActionsImage = 'childrenAnswerYaml___actions___image',
  ChildrenAnswerYamlActionsActions = 'childrenAnswerYaml___actions___actions',
  ChildrenAnswerYamlActionsActionsId = 'childrenAnswerYaml___actions___actions___id',
  ChildrenAnswerYamlActionsActionsLabel = 'childrenAnswerYaml___actions___actions___label',
  ChildrenAnswerYamlActionsActionsExternal = 'childrenAnswerYaml___actions___actions___external',
  ChildrenAnswerYamlActionsActionsActionId = 'childrenAnswerYaml___actions___actions___actionId',
  ChildrenAnswerYamlParentId = 'childrenAnswerYaml___parent___id',
  ChildrenAnswerYamlParentParentId = 'childrenAnswerYaml___parent___parent___id',
  ChildrenAnswerYamlParentParentChildren = 'childrenAnswerYaml___parent___parent___children',
  ChildrenAnswerYamlParentChildren = 'childrenAnswerYaml___parent___children',
  ChildrenAnswerYamlParentChildrenId = 'childrenAnswerYaml___parent___children___id',
  ChildrenAnswerYamlParentChildrenChildren = 'childrenAnswerYaml___parent___children___children',
  ChildrenAnswerYamlParentInternalContent = 'childrenAnswerYaml___parent___internal___content',
  ChildrenAnswerYamlParentInternalContentDigest = 'childrenAnswerYaml___parent___internal___contentDigest',
  ChildrenAnswerYamlParentInternalDescription = 'childrenAnswerYaml___parent___internal___description',
  ChildrenAnswerYamlParentInternalFieldOwners = 'childrenAnswerYaml___parent___internal___fieldOwners',
  ChildrenAnswerYamlParentInternalIgnoreType = 'childrenAnswerYaml___parent___internal___ignoreType',
  ChildrenAnswerYamlParentInternalMediaType = 'childrenAnswerYaml___parent___internal___mediaType',
  ChildrenAnswerYamlParentInternalOwner = 'childrenAnswerYaml___parent___internal___owner',
  ChildrenAnswerYamlParentInternalType = 'childrenAnswerYaml___parent___internal___type',
  ChildrenAnswerYamlChildren = 'childrenAnswerYaml___children',
  ChildrenAnswerYamlChildrenId = 'childrenAnswerYaml___children___id',
  ChildrenAnswerYamlChildrenParentId = 'childrenAnswerYaml___children___parent___id',
  ChildrenAnswerYamlChildrenParentChildren = 'childrenAnswerYaml___children___parent___children',
  ChildrenAnswerYamlChildrenChildren = 'childrenAnswerYaml___children___children',
  ChildrenAnswerYamlChildrenChildrenId = 'childrenAnswerYaml___children___children___id',
  ChildrenAnswerYamlChildrenChildrenChildren = 'childrenAnswerYaml___children___children___children',
  ChildrenAnswerYamlChildrenInternalContent = 'childrenAnswerYaml___children___internal___content',
  ChildrenAnswerYamlChildrenInternalContentDigest = 'childrenAnswerYaml___children___internal___contentDigest',
  ChildrenAnswerYamlChildrenInternalDescription = 'childrenAnswerYaml___children___internal___description',
  ChildrenAnswerYamlChildrenInternalFieldOwners = 'childrenAnswerYaml___children___internal___fieldOwners',
  ChildrenAnswerYamlChildrenInternalIgnoreType = 'childrenAnswerYaml___children___internal___ignoreType',
  ChildrenAnswerYamlChildrenInternalMediaType = 'childrenAnswerYaml___children___internal___mediaType',
  ChildrenAnswerYamlChildrenInternalOwner = 'childrenAnswerYaml___children___internal___owner',
  ChildrenAnswerYamlChildrenInternalType = 'childrenAnswerYaml___children___internal___type',
  ChildrenAnswerYamlInternalContent = 'childrenAnswerYaml___internal___content',
  ChildrenAnswerYamlInternalContentDigest = 'childrenAnswerYaml___internal___contentDigest',
  ChildrenAnswerYamlInternalDescription = 'childrenAnswerYaml___internal___description',
  ChildrenAnswerYamlInternalFieldOwners = 'childrenAnswerYaml___internal___fieldOwners',
  ChildrenAnswerYamlInternalIgnoreType = 'childrenAnswerYaml___internal___ignoreType',
  ChildrenAnswerYamlInternalMediaType = 'childrenAnswerYaml___internal___mediaType',
  ChildrenAnswerYamlInternalOwner = 'childrenAnswerYaml___internal___owner',
  ChildrenAnswerYamlInternalType = 'childrenAnswerYaml___internal___type',
  ChildrenCategoryYaml = 'childrenCategoryYaml',
  ChildrenCategoryYamlIgnore = 'childrenCategoryYaml___ignore',
  ChildrenCategoryYamlId = 'childrenCategoryYaml___id',
  ChildrenCategoryYamlTitle = 'childrenCategoryYaml___title',
  ChildrenCategoryYamlParentId = 'childrenCategoryYaml___parent___id',
  ChildrenCategoryYamlParentParentId = 'childrenCategoryYaml___parent___parent___id',
  ChildrenCategoryYamlParentParentChildren = 'childrenCategoryYaml___parent___parent___children',
  ChildrenCategoryYamlParentChildren = 'childrenCategoryYaml___parent___children',
  ChildrenCategoryYamlParentChildrenId = 'childrenCategoryYaml___parent___children___id',
  ChildrenCategoryYamlParentChildrenChildren = 'childrenCategoryYaml___parent___children___children',
  ChildrenCategoryYamlParentInternalContent = 'childrenCategoryYaml___parent___internal___content',
  ChildrenCategoryYamlParentInternalContentDigest = 'childrenCategoryYaml___parent___internal___contentDigest',
  ChildrenCategoryYamlParentInternalDescription = 'childrenCategoryYaml___parent___internal___description',
  ChildrenCategoryYamlParentInternalFieldOwners = 'childrenCategoryYaml___parent___internal___fieldOwners',
  ChildrenCategoryYamlParentInternalIgnoreType = 'childrenCategoryYaml___parent___internal___ignoreType',
  ChildrenCategoryYamlParentInternalMediaType = 'childrenCategoryYaml___parent___internal___mediaType',
  ChildrenCategoryYamlParentInternalOwner = 'childrenCategoryYaml___parent___internal___owner',
  ChildrenCategoryYamlParentInternalType = 'childrenCategoryYaml___parent___internal___type',
  ChildrenCategoryYamlSub = 'childrenCategoryYaml___sub',
  ChildrenCategoryYamlSubId = 'childrenCategoryYaml___sub___id',
  ChildrenCategoryYamlSubTitle = 'childrenCategoryYaml___sub___title',
  ChildrenCategoryYamlSubParent = 'childrenCategoryYaml___sub___parent',
  ChildrenCategoryYamlSubTags = 'childrenCategoryYaml___sub___tags',
  ChildrenCategoryYamlTags = 'childrenCategoryYaml___tags',
  ChildrenCategoryYamlChildren = 'childrenCategoryYaml___children',
  ChildrenCategoryYamlChildrenId = 'childrenCategoryYaml___children___id',
  ChildrenCategoryYamlChildrenParentId = 'childrenCategoryYaml___children___parent___id',
  ChildrenCategoryYamlChildrenParentChildren = 'childrenCategoryYaml___children___parent___children',
  ChildrenCategoryYamlChildrenChildren = 'childrenCategoryYaml___children___children',
  ChildrenCategoryYamlChildrenChildrenId = 'childrenCategoryYaml___children___children___id',
  ChildrenCategoryYamlChildrenChildrenChildren = 'childrenCategoryYaml___children___children___children',
  ChildrenCategoryYamlChildrenInternalContent = 'childrenCategoryYaml___children___internal___content',
  ChildrenCategoryYamlChildrenInternalContentDigest = 'childrenCategoryYaml___children___internal___contentDigest',
  ChildrenCategoryYamlChildrenInternalDescription = 'childrenCategoryYaml___children___internal___description',
  ChildrenCategoryYamlChildrenInternalFieldOwners = 'childrenCategoryYaml___children___internal___fieldOwners',
  ChildrenCategoryYamlChildrenInternalIgnoreType = 'childrenCategoryYaml___children___internal___ignoreType',
  ChildrenCategoryYamlChildrenInternalMediaType = 'childrenCategoryYaml___children___internal___mediaType',
  ChildrenCategoryYamlChildrenInternalOwner = 'childrenCategoryYaml___children___internal___owner',
  ChildrenCategoryYamlChildrenInternalType = 'childrenCategoryYaml___children___internal___type',
  ChildrenCategoryYamlInternalContent = 'childrenCategoryYaml___internal___content',
  ChildrenCategoryYamlInternalContentDigest = 'childrenCategoryYaml___internal___contentDigest',
  ChildrenCategoryYamlInternalDescription = 'childrenCategoryYaml___internal___description',
  ChildrenCategoryYamlInternalFieldOwners = 'childrenCategoryYaml___internal___fieldOwners',
  ChildrenCategoryYamlInternalIgnoreType = 'childrenCategoryYaml___internal___ignoreType',
  ChildrenCategoryYamlInternalMediaType = 'childrenCategoryYaml___internal___mediaType',
  ChildrenCategoryYamlInternalOwner = 'childrenCategoryYaml___internal___owner',
  ChildrenCategoryYamlInternalType = 'childrenCategoryYaml___internal___type',
  ChildrenTagYaml = 'childrenTagYaml',
  ChildrenTagYamlId = 'childrenTagYaml___id',
  ChildrenTagYamlAnswers = 'childrenTagYaml___answers',
  ChildrenTagYamlRelations = 'childrenTagYaml___relations',
  ChildrenTagYamlIgnore = 'childrenTagYaml___ignore',
  ChildrenTagYamlParentId = 'childrenTagYaml___parent___id',
  ChildrenTagYamlParentParentId = 'childrenTagYaml___parent___parent___id',
  ChildrenTagYamlParentParentChildren = 'childrenTagYaml___parent___parent___children',
  ChildrenTagYamlParentChildren = 'childrenTagYaml___parent___children',
  ChildrenTagYamlParentChildrenId = 'childrenTagYaml___parent___children___id',
  ChildrenTagYamlParentChildrenChildren = 'childrenTagYaml___parent___children___children',
  ChildrenTagYamlParentInternalContent = 'childrenTagYaml___parent___internal___content',
  ChildrenTagYamlParentInternalContentDigest = 'childrenTagYaml___parent___internal___contentDigest',
  ChildrenTagYamlParentInternalDescription = 'childrenTagYaml___parent___internal___description',
  ChildrenTagYamlParentInternalFieldOwners = 'childrenTagYaml___parent___internal___fieldOwners',
  ChildrenTagYamlParentInternalIgnoreType = 'childrenTagYaml___parent___internal___ignoreType',
  ChildrenTagYamlParentInternalMediaType = 'childrenTagYaml___parent___internal___mediaType',
  ChildrenTagYamlParentInternalOwner = 'childrenTagYaml___parent___internal___owner',
  ChildrenTagYamlParentInternalType = 'childrenTagYaml___parent___internal___type',
  ChildrenTagYamlChildren = 'childrenTagYaml___children',
  ChildrenTagYamlChildrenId = 'childrenTagYaml___children___id',
  ChildrenTagYamlChildrenParentId = 'childrenTagYaml___children___parent___id',
  ChildrenTagYamlChildrenParentChildren = 'childrenTagYaml___children___parent___children',
  ChildrenTagYamlChildrenChildren = 'childrenTagYaml___children___children',
  ChildrenTagYamlChildrenChildrenId = 'childrenTagYaml___children___children___id',
  ChildrenTagYamlChildrenChildrenChildren = 'childrenTagYaml___children___children___children',
  ChildrenTagYamlChildrenInternalContent = 'childrenTagYaml___children___internal___content',
  ChildrenTagYamlChildrenInternalContentDigest = 'childrenTagYaml___children___internal___contentDigest',
  ChildrenTagYamlChildrenInternalDescription = 'childrenTagYaml___children___internal___description',
  ChildrenTagYamlChildrenInternalFieldOwners = 'childrenTagYaml___children___internal___fieldOwners',
  ChildrenTagYamlChildrenInternalIgnoreType = 'childrenTagYaml___children___internal___ignoreType',
  ChildrenTagYamlChildrenInternalMediaType = 'childrenTagYaml___children___internal___mediaType',
  ChildrenTagYamlChildrenInternalOwner = 'childrenTagYaml___children___internal___owner',
  ChildrenTagYamlChildrenInternalType = 'childrenTagYaml___children___internal___type',
  ChildrenTagYamlInternalContent = 'childrenTagYaml___internal___content',
  ChildrenTagYamlInternalContentDigest = 'childrenTagYaml___internal___contentDigest',
  ChildrenTagYamlInternalDescription = 'childrenTagYaml___internal___description',
  ChildrenTagYamlInternalFieldOwners = 'childrenTagYaml___internal___fieldOwners',
  ChildrenTagYamlInternalIgnoreType = 'childrenTagYaml___internal___ignoreType',
  ChildrenTagYamlInternalMediaType = 'childrenTagYaml___internal___mediaType',
  ChildrenTagYamlInternalOwner = 'childrenTagYaml___internal___owner',
  ChildrenTagYamlInternalType = 'childrenTagYaml___internal___type',
  ChildrenSettingYaml = 'childrenSettingYaml',
  ChildrenSettingYamlBotId = 'childrenSettingYaml___botId',
  ChildrenSettingYamlCustomContents = 'childrenSettingYaml___customContents',
  ChildrenSettingYamlCustomContentsId = 'childrenSettingYaml___customContents___id',
  ChildrenSettingYamlCustomContentsType = 'childrenSettingYaml___customContents___type',
  ChildrenSettingYamlCustomContentsTitle = 'childrenSettingYaml___customContents___title',
  ChildrenSettingYamlCustomContentsParts = 'childrenSettingYaml___customContents___parts',
  ChildrenSettingYamlCustomContentsPartsId = 'childrenSettingYaml___customContents___parts___id',
  ChildrenSettingYamlCustomContentsPartsTitle = 'childrenSettingYaml___customContents___parts___title',
  ChildrenSettingYamlCustomContentsPartsImage = 'childrenSettingYaml___customContents___parts___image',
  ChildrenSettingYamlDeploymentHostName = 'childrenSettingYaml___deploymentHostName',
  ChildrenSettingYamlDescription = 'childrenSettingYaml___description',
  ChildrenSettingYamlEnabled = 'childrenSettingYaml___enabled',
  ChildrenSettingYamlExcludeDuplication = 'childrenSettingYaml___excludeDuplication',
  ChildrenSettingYamlExcludes = 'childrenSettingYaml___excludes',
  ChildrenSettingYamlExcludesId = 'childrenSettingYaml___excludes___id',
  ChildrenSettingYamlExcludesTitle = 'childrenSettingYaml___excludes___title',
  ChildrenSettingYamlFacebook = 'childrenSettingYaml___facebook',
  ChildrenSettingYamlFallbackUrl = 'childrenSettingYaml___fallbackUrl',
  ChildrenSettingYamlFaviconUrl = 'childrenSettingYaml___faviconUrl',
  ChildrenSettingYamlFooter = 'childrenSettingYaml___footer',
  ChildrenSettingYamlGtags = 'childrenSettingYaml___gtags',
  ChildrenSettingYamlHeader = 'childrenSettingYaml___header',
  ChildrenSettingYamlHeaderImageAltText = 'childrenSettingYaml___headerImageAltText',
  ChildrenSettingYamlHeaderImageLinkUrl = 'childrenSettingYaml___headerImageLinkUrl',
  ChildrenSettingYamlHeaderImageUrl = 'childrenSettingYaml___headerImageUrl',
  ChildrenSettingYamlIgnore = 'childrenSettingYaml___ignore',
  ChildrenSettingYamlKarakuriWebRootUrl = 'childrenSettingYaml___karakuriWebRootUrl',
  ChildrenSettingYamlLanguage = 'childrenSettingYaml___language',
  ChildrenSettingYamlLayoutLeft = 'childrenSettingYaml___layoutLeft',
  ChildrenSettingYamlLayoutLeftId = 'childrenSettingYaml___layoutLeft___id',
  ChildrenSettingYamlLayoutLeftTitle = 'childrenSettingYaml___layoutLeft___title',
  ChildrenSettingYamlLayoutLeftType = 'childrenSettingYaml___layoutLeft___type',
  ChildrenSettingYamlLayoutLeftIcons = 'childrenSettingYaml___layoutLeft___icons',
  ChildrenSettingYamlLayoutLeftIconsId = 'childrenSettingYaml___layoutLeft___icons___id',
  ChildrenSettingYamlLayoutLeftIconsTitle = 'childrenSettingYaml___layoutLeft___icons___title',
  ChildrenSettingYamlLayoutLeftIconsReference = 'childrenSettingYaml___layoutLeft___icons___reference',
  ChildrenSettingYamlLayoutLeftIconsImage = 'childrenSettingYaml___layoutLeft___icons___image',
  ChildrenSettingYamlLayoutMain = 'childrenSettingYaml___layoutMain',
  ChildrenSettingYamlLayoutMainId = 'childrenSettingYaml___layoutMain___id',
  ChildrenSettingYamlLayoutMainTitle = 'childrenSettingYaml___layoutMain___title',
  ChildrenSettingYamlLayoutMainType = 'childrenSettingYaml___layoutMain___type',
  ChildrenSettingYamlLayoutMainIcons = 'childrenSettingYaml___layoutMain___icons',
  ChildrenSettingYamlLayoutMainIconsId = 'childrenSettingYaml___layoutMain___icons___id',
  ChildrenSettingYamlLayoutMainIconsTitle = 'childrenSettingYaml___layoutMain___icons___title',
  ChildrenSettingYamlLayoutMainIconsReference = 'childrenSettingYaml___layoutMain___icons___reference',
  ChildrenSettingYamlLayoutMainIconsImage = 'childrenSettingYaml___layoutMain___icons___image',
  ChildrenSettingYamlLayoutRight = 'childrenSettingYaml___layoutRight',
  ChildrenSettingYamlLayoutRightId = 'childrenSettingYaml___layoutRight___id',
  ChildrenSettingYamlLayoutRightTitle = 'childrenSettingYaml___layoutRight___title',
  ChildrenSettingYamlLayoutRightType = 'childrenSettingYaml___layoutRight___type',
  ChildrenSettingYamlLayoutRightIcons = 'childrenSettingYaml___layoutRight___icons',
  ChildrenSettingYamlLayoutRightIconsId = 'childrenSettingYaml___layoutRight___icons___id',
  ChildrenSettingYamlLayoutRightIconsTitle = 'childrenSettingYaml___layoutRight___icons___title',
  ChildrenSettingYamlLayoutRightIconsReference = 'childrenSettingYaml___layoutRight___icons___reference',
  ChildrenSettingYamlLayoutRightIconsImage = 'childrenSettingYaml___layoutRight___icons___image',
  ChildrenSettingYamlTagLayoutLeft = 'childrenSettingYaml___tagLayoutLeft',
  ChildrenSettingYamlTagLayoutLeftId = 'childrenSettingYaml___tagLayoutLeft___id',
  ChildrenSettingYamlTagLayoutLeftTitle = 'childrenSettingYaml___tagLayoutLeft___title',
  ChildrenSettingYamlTagLayoutLeftType = 'childrenSettingYaml___tagLayoutLeft___type',
  ChildrenSettingYamlTagLayoutLeftIcons = 'childrenSettingYaml___tagLayoutLeft___icons',
  ChildrenSettingYamlTagLayoutLeftIconsId = 'childrenSettingYaml___tagLayoutLeft___icons___id',
  ChildrenSettingYamlTagLayoutLeftIconsTitle = 'childrenSettingYaml___tagLayoutLeft___icons___title',
  ChildrenSettingYamlTagLayoutLeftIconsReference = 'childrenSettingYaml___tagLayoutLeft___icons___reference',
  ChildrenSettingYamlTagLayoutLeftIconsImage = 'childrenSettingYaml___tagLayoutLeft___icons___image',
  ChildrenSettingYamlTagLayoutMain = 'childrenSettingYaml___tagLayoutMain',
  ChildrenSettingYamlTagLayoutMainId = 'childrenSettingYaml___tagLayoutMain___id',
  ChildrenSettingYamlTagLayoutMainTitle = 'childrenSettingYaml___tagLayoutMain___title',
  ChildrenSettingYamlTagLayoutMainType = 'childrenSettingYaml___tagLayoutMain___type',
  ChildrenSettingYamlTagLayoutMainIcons = 'childrenSettingYaml___tagLayoutMain___icons',
  ChildrenSettingYamlTagLayoutMainIconsId = 'childrenSettingYaml___tagLayoutMain___icons___id',
  ChildrenSettingYamlTagLayoutMainIconsTitle = 'childrenSettingYaml___tagLayoutMain___icons___title',
  ChildrenSettingYamlTagLayoutMainIconsReference = 'childrenSettingYaml___tagLayoutMain___icons___reference',
  ChildrenSettingYamlTagLayoutMainIconsImage = 'childrenSettingYaml___tagLayoutMain___icons___image',
  ChildrenSettingYamlTagLayoutRight = 'childrenSettingYaml___tagLayoutRight',
  ChildrenSettingYamlTagLayoutRightId = 'childrenSettingYaml___tagLayoutRight___id',
  ChildrenSettingYamlTagLayoutRightTitle = 'childrenSettingYaml___tagLayoutRight___title',
  ChildrenSettingYamlTagLayoutRightType = 'childrenSettingYaml___tagLayoutRight___type',
  ChildrenSettingYamlTagLayoutRightIcons = 'childrenSettingYaml___tagLayoutRight___icons',
  ChildrenSettingYamlTagLayoutRightIconsId = 'childrenSettingYaml___tagLayoutRight___icons___id',
  ChildrenSettingYamlTagLayoutRightIconsTitle = 'childrenSettingYaml___tagLayoutRight___icons___title',
  ChildrenSettingYamlTagLayoutRightIconsReference = 'childrenSettingYaml___tagLayoutRight___icons___reference',
  ChildrenSettingYamlTagLayoutRightIconsImage = 'childrenSettingYaml___tagLayoutRight___icons___image',
  ChildrenSettingYamlAnswerLayoutLeft = 'childrenSettingYaml___answerLayoutLeft',
  ChildrenSettingYamlAnswerLayoutLeftId = 'childrenSettingYaml___answerLayoutLeft___id',
  ChildrenSettingYamlAnswerLayoutLeftTitle = 'childrenSettingYaml___answerLayoutLeft___title',
  ChildrenSettingYamlAnswerLayoutLeftType = 'childrenSettingYaml___answerLayoutLeft___type',
  ChildrenSettingYamlAnswerLayoutLeftIcons = 'childrenSettingYaml___answerLayoutLeft___icons',
  ChildrenSettingYamlAnswerLayoutLeftIconsId = 'childrenSettingYaml___answerLayoutLeft___icons___id',
  ChildrenSettingYamlAnswerLayoutLeftIconsTitle = 'childrenSettingYaml___answerLayoutLeft___icons___title',
  ChildrenSettingYamlAnswerLayoutLeftIconsReference = 'childrenSettingYaml___answerLayoutLeft___icons___reference',
  ChildrenSettingYamlAnswerLayoutLeftIconsImage = 'childrenSettingYaml___answerLayoutLeft___icons___image',
  ChildrenSettingYamlAnswerLayoutMain = 'childrenSettingYaml___answerLayoutMain',
  ChildrenSettingYamlAnswerLayoutMainId = 'childrenSettingYaml___answerLayoutMain___id',
  ChildrenSettingYamlAnswerLayoutMainTitle = 'childrenSettingYaml___answerLayoutMain___title',
  ChildrenSettingYamlAnswerLayoutMainType = 'childrenSettingYaml___answerLayoutMain___type',
  ChildrenSettingYamlAnswerLayoutMainIcons = 'childrenSettingYaml___answerLayoutMain___icons',
  ChildrenSettingYamlAnswerLayoutMainIconsId = 'childrenSettingYaml___answerLayoutMain___icons___id',
  ChildrenSettingYamlAnswerLayoutMainIconsTitle = 'childrenSettingYaml___answerLayoutMain___icons___title',
  ChildrenSettingYamlAnswerLayoutMainIconsReference = 'childrenSettingYaml___answerLayoutMain___icons___reference',
  ChildrenSettingYamlAnswerLayoutMainIconsImage = 'childrenSettingYaml___answerLayoutMain___icons___image',
  ChildrenSettingYamlAnswerLayoutRight = 'childrenSettingYaml___answerLayoutRight',
  ChildrenSettingYamlAnswerLayoutRightId = 'childrenSettingYaml___answerLayoutRight___id',
  ChildrenSettingYamlAnswerLayoutRightTitle = 'childrenSettingYaml___answerLayoutRight___title',
  ChildrenSettingYamlAnswerLayoutRightType = 'childrenSettingYaml___answerLayoutRight___type',
  ChildrenSettingYamlAnswerLayoutRightIcons = 'childrenSettingYaml___answerLayoutRight___icons',
  ChildrenSettingYamlAnswerLayoutRightIconsId = 'childrenSettingYaml___answerLayoutRight___icons___id',
  ChildrenSettingYamlAnswerLayoutRightIconsTitle = 'childrenSettingYaml___answerLayoutRight___icons___title',
  ChildrenSettingYamlAnswerLayoutRightIconsReference = 'childrenSettingYaml___answerLayoutRight___icons___reference',
  ChildrenSettingYamlAnswerLayoutRightIconsImage = 'childrenSettingYaml___answerLayoutRight___icons___image',
  ChildrenSettingYamlNrOfDisplay = 'childrenSettingYaml___nrOfDisplay',
  ChildrenSettingYamlPvTrackingId = 'childrenSettingYaml___pvTrackingId',
  ChildrenSettingYamlPvViewId = 'childrenSettingYaml___pvViewId',
  ChildrenSettingYamlQuestionnaireYes = 'childrenSettingYaml___questionnaire___yes',
  ChildrenSettingYamlQuestionnaireNo = 'childrenSettingYaml___questionnaire___no',
  ChildrenSettingYamlQuestionnaireTextNo = 'childrenSettingYaml___questionnaireTextNo',
  ChildrenSettingYamlQuestionnaireTextYes = 'childrenSettingYaml___questionnaireTextYes',
  ChildrenSettingYamlScripts = 'childrenSettingYaml___scripts',
  ChildrenSettingYamlStyles = 'childrenSettingYaml___styles',
  ChildrenSettingYamlTitle = 'childrenSettingYaml___title',
  ChildrenSettingYamlTwitter = 'childrenSettingYaml___twitter',
  ChildrenSettingYamlUrl = 'childrenSettingYaml___url',
  ChildrenSettingYamlTagCloud = 'childrenSettingYaml___tagCloud',
  ChildrenSettingYamlCategories = 'childrenSettingYaml___categories',
  ChildrenSettingYamlCategoriesId = 'childrenSettingYaml___categories___id',
  ChildrenSettingYamlCategoriesTitle = 'childrenSettingYaml___categories___title',
  ChildrenSettingYamlCategoriesSub = 'childrenSettingYaml___categories___sub',
  ChildrenSettingYamlCategoriesSubId = 'childrenSettingYaml___categories___sub___id',
  ChildrenSettingYamlCategoriesSubTitle = 'childrenSettingYaml___categories___sub___title',
  ChildrenSettingYamlCategoriesSubParent = 'childrenSettingYaml___categories___sub___parent',
  ChildrenSettingYamlCategoriesSubTags = 'childrenSettingYaml___categories___sub___tags',
  ChildrenSettingYamlCategoriesTags = 'childrenSettingYaml___categories___tags',
  ChildrenSettingYamlExcludeFromFaq = 'childrenSettingYaml___excludeFromFAQ',
  ChildrenSettingYamlExcludeFromFaqId = 'childrenSettingYaml___excludeFromFAQ___id',
  ChildrenSettingYamlExcludeFromFaqTitle = 'childrenSettingYaml___excludeFromFAQ___title',
  ChildrenSettingYamlDeploy = 'childrenSettingYaml___deploy',
  ChildrenSettingYamlInformations = 'childrenSettingYaml___informations',
  ChildrenSettingYamlInformationsId = 'childrenSettingYaml___informations___id',
  ChildrenSettingYamlInformationsTitle = 'childrenSettingYaml___informations___title',
  ChildrenSettingYamlImportants = 'childrenSettingYaml___importants',
  ChildrenSettingYamlImportantsId = 'childrenSettingYaml___importants___id',
  ChildrenSettingYamlImportantsTitle = 'childrenSettingYaml___importants___title',
  ChildrenSettingYamlLayout = 'childrenSettingYaml___layout',
  ChildrenSettingYamlId = 'childrenSettingYaml___id',
  ChildrenSettingYamlParentId = 'childrenSettingYaml___parent___id',
  ChildrenSettingYamlParentParentId = 'childrenSettingYaml___parent___parent___id',
  ChildrenSettingYamlParentParentChildren = 'childrenSettingYaml___parent___parent___children',
  ChildrenSettingYamlParentChildren = 'childrenSettingYaml___parent___children',
  ChildrenSettingYamlParentChildrenId = 'childrenSettingYaml___parent___children___id',
  ChildrenSettingYamlParentChildrenChildren = 'childrenSettingYaml___parent___children___children',
  ChildrenSettingYamlParentInternalContent = 'childrenSettingYaml___parent___internal___content',
  ChildrenSettingYamlParentInternalContentDigest = 'childrenSettingYaml___parent___internal___contentDigest',
  ChildrenSettingYamlParentInternalDescription = 'childrenSettingYaml___parent___internal___description',
  ChildrenSettingYamlParentInternalFieldOwners = 'childrenSettingYaml___parent___internal___fieldOwners',
  ChildrenSettingYamlParentInternalIgnoreType = 'childrenSettingYaml___parent___internal___ignoreType',
  ChildrenSettingYamlParentInternalMediaType = 'childrenSettingYaml___parent___internal___mediaType',
  ChildrenSettingYamlParentInternalOwner = 'childrenSettingYaml___parent___internal___owner',
  ChildrenSettingYamlParentInternalType = 'childrenSettingYaml___parent___internal___type',
  ChildrenSettingYamlChildren = 'childrenSettingYaml___children',
  ChildrenSettingYamlChildrenId = 'childrenSettingYaml___children___id',
  ChildrenSettingYamlChildrenParentId = 'childrenSettingYaml___children___parent___id',
  ChildrenSettingYamlChildrenParentChildren = 'childrenSettingYaml___children___parent___children',
  ChildrenSettingYamlChildrenChildren = 'childrenSettingYaml___children___children',
  ChildrenSettingYamlChildrenChildrenId = 'childrenSettingYaml___children___children___id',
  ChildrenSettingYamlChildrenChildrenChildren = 'childrenSettingYaml___children___children___children',
  ChildrenSettingYamlChildrenInternalContent = 'childrenSettingYaml___children___internal___content',
  ChildrenSettingYamlChildrenInternalContentDigest = 'childrenSettingYaml___children___internal___contentDigest',
  ChildrenSettingYamlChildrenInternalDescription = 'childrenSettingYaml___children___internal___description',
  ChildrenSettingYamlChildrenInternalFieldOwners = 'childrenSettingYaml___children___internal___fieldOwners',
  ChildrenSettingYamlChildrenInternalIgnoreType = 'childrenSettingYaml___children___internal___ignoreType',
  ChildrenSettingYamlChildrenInternalMediaType = 'childrenSettingYaml___children___internal___mediaType',
  ChildrenSettingYamlChildrenInternalOwner = 'childrenSettingYaml___children___internal___owner',
  ChildrenSettingYamlChildrenInternalType = 'childrenSettingYaml___children___internal___type',
  ChildrenSettingYamlInternalContent = 'childrenSettingYaml___internal___content',
  ChildrenSettingYamlInternalContentDigest = 'childrenSettingYaml___internal___contentDigest',
  ChildrenSettingYamlInternalDescription = 'childrenSettingYaml___internal___description',
  ChildrenSettingYamlInternalFieldOwners = 'childrenSettingYaml___internal___fieldOwners',
  ChildrenSettingYamlInternalIgnoreType = 'childrenSettingYaml___internal___ignoreType',
  ChildrenSettingYamlInternalMediaType = 'childrenSettingYaml___internal___mediaType',
  ChildrenSettingYamlInternalOwner = 'childrenSettingYaml___internal___owner',
  ChildrenSettingYamlInternalType = 'childrenSettingYaml___internal___type'
}

export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ImageSharpFieldsEnum {
  FixedBase64 = 'fixed___base64',
  FixedTracedSvg = 'fixed___tracedSVG',
  FixedAspectRatio = 'fixed___aspectRatio',
  FixedWidth = 'fixed___width',
  FixedHeight = 'fixed___height',
  FixedSrc = 'fixed___src',
  FixedSrcSet = 'fixed___srcSet',
  FixedSrcWebp = 'fixed___srcWebp',
  FixedSrcSetWebp = 'fixed___srcSetWebp',
  FixedOriginalName = 'fixed___originalName',
  ResolutionsBase64 = 'resolutions___base64',
  ResolutionsTracedSvg = 'resolutions___tracedSVG',
  ResolutionsAspectRatio = 'resolutions___aspectRatio',
  ResolutionsWidth = 'resolutions___width',
  ResolutionsHeight = 'resolutions___height',
  ResolutionsSrc = 'resolutions___src',
  ResolutionsSrcSet = 'resolutions___srcSet',
  ResolutionsSrcWebp = 'resolutions___srcWebp',
  ResolutionsSrcSetWebp = 'resolutions___srcSetWebp',
  ResolutionsOriginalName = 'resolutions___originalName',
  FluidBase64 = 'fluid___base64',
  FluidTracedSvg = 'fluid___tracedSVG',
  FluidAspectRatio = 'fluid___aspectRatio',
  FluidSrc = 'fluid___src',
  FluidSrcSet = 'fluid___srcSet',
  FluidSrcWebp = 'fluid___srcWebp',
  FluidSrcSetWebp = 'fluid___srcSetWebp',
  FluidSizes = 'fluid___sizes',
  FluidOriginalImg = 'fluid___originalImg',
  FluidOriginalName = 'fluid___originalName',
  FluidPresentationWidth = 'fluid___presentationWidth',
  FluidPresentationHeight = 'fluid___presentationHeight',
  SizesBase64 = 'sizes___base64',
  SizesTracedSvg = 'sizes___tracedSVG',
  SizesAspectRatio = 'sizes___aspectRatio',
  SizesSrc = 'sizes___src',
  SizesSrcSet = 'sizes___srcSet',
  SizesSrcWebp = 'sizes___srcWebp',
  SizesSrcSetWebp = 'sizes___srcSetWebp',
  SizesSizes = 'sizes___sizes',
  SizesOriginalImg = 'sizes___originalImg',
  SizesOriginalName = 'sizes___originalName',
  SizesPresentationWidth = 'sizes___presentationWidth',
  SizesPresentationHeight = 'sizes___presentationHeight',
  OriginalWidth = 'original___width',
  OriginalHeight = 'original___height',
  OriginalSrc = 'original___src',
  ResizeSrc = 'resize___src',
  ResizeTracedSvg = 'resize___tracedSVG',
  ResizeWidth = 'resize___width',
  ResizeHeight = 'resize___height',
  ResizeAspectRatio = 'resize___aspectRatio',
  ResizeOriginalName = 'resize___originalName',
  Id = 'id',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type'
}

export enum AnswerYamlFieldsEnum {
  Ignore = 'ignore',
  Id = 'id',
  CardId = 'cardId',
  Ask = 'ask',
  Contents = 'contents',
  ContentsText = 'contents___text',
  ContentsContentFormat = 'contents___contentFormat',
  ContentsImage = 'contents___image',
  ActionsId = 'actions___id',
  ActionsImage = 'actions___image',
  ActionsActions = 'actions___actions',
  ActionsActionsId = 'actions___actions___id',
  ActionsActionsLabel = 'actions___actions___label',
  ActionsActionsExternal = 'actions___actions___external',
  ActionsActionsActionId = 'actions___actions___actionId',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type'
}

export enum CategoryYamlFieldsEnum {
  Ignore = 'ignore',
  Id = 'id',
  Title = 'title',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Sub = 'sub',
  SubId = 'sub___id',
  SubTitle = 'sub___title',
  SubParent = 'sub___parent',
  SubTags = 'sub___tags',
  Tags = 'tags',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type'
}

export enum ImportantYamlFieldsEnum {
  Id = 'id',
  Title = 'title',
  Ignore = 'ignore',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type'
}

export enum InformationYamlFieldsEnum {
  Id = 'id',
  Title = 'title',
  Ignore = 'ignore',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type'
}

export enum SettingYamlFieldsEnum {
  BotId = 'botId',
  CustomContents = 'customContents',
  CustomContentsId = 'customContents___id',
  CustomContentsType = 'customContents___type',
  CustomContentsTitle = 'customContents___title',
  CustomContentsParts = 'customContents___parts',
  CustomContentsPartsId = 'customContents___parts___id',
  CustomContentsPartsTitle = 'customContents___parts___title',
  CustomContentsPartsImage = 'customContents___parts___image',
  DeploymentHostName = 'deploymentHostName',
  Description = 'description',
  Enabled = 'enabled',
  ExcludeDuplication = 'excludeDuplication',
  Excludes = 'excludes',
  ExcludesId = 'excludes___id',
  ExcludesTitle = 'excludes___title',
  Facebook = 'facebook',
  FallbackUrl = 'fallbackUrl',
  FaviconUrl = 'faviconUrl',
  Footer = 'footer',
  Gtags = 'gtags',
  Header = 'header',
  HeaderImageAltText = 'headerImageAltText',
  HeaderImageLinkUrl = 'headerImageLinkUrl',
  HeaderImageUrl = 'headerImageUrl',
  Ignore = 'ignore',
  KarakuriWebRootUrl = 'karakuriWebRootUrl',
  Language = 'language',
  LayoutLeft = 'layoutLeft',
  LayoutLeftId = 'layoutLeft___id',
  LayoutLeftTitle = 'layoutLeft___title',
  LayoutLeftType = 'layoutLeft___type',
  LayoutLeftIcons = 'layoutLeft___icons',
  LayoutLeftIconsId = 'layoutLeft___icons___id',
  LayoutLeftIconsTitle = 'layoutLeft___icons___title',
  LayoutLeftIconsReference = 'layoutLeft___icons___reference',
  LayoutLeftIconsImage = 'layoutLeft___icons___image',
  LayoutMain = 'layoutMain',
  LayoutMainId = 'layoutMain___id',
  LayoutMainTitle = 'layoutMain___title',
  LayoutMainType = 'layoutMain___type',
  LayoutMainIcons = 'layoutMain___icons',
  LayoutMainIconsId = 'layoutMain___icons___id',
  LayoutMainIconsTitle = 'layoutMain___icons___title',
  LayoutMainIconsReference = 'layoutMain___icons___reference',
  LayoutMainIconsImage = 'layoutMain___icons___image',
  LayoutRight = 'layoutRight',
  LayoutRightId = 'layoutRight___id',
  LayoutRightTitle = 'layoutRight___title',
  LayoutRightType = 'layoutRight___type',
  LayoutRightIcons = 'layoutRight___icons',
  LayoutRightIconsId = 'layoutRight___icons___id',
  LayoutRightIconsTitle = 'layoutRight___icons___title',
  LayoutRightIconsReference = 'layoutRight___icons___reference',
  LayoutRightIconsImage = 'layoutRight___icons___image',
  TagLayoutLeft = 'tagLayoutLeft',
  TagLayoutLeftId = 'tagLayoutLeft___id',
  TagLayoutLeftTitle = 'tagLayoutLeft___title',
  TagLayoutLeftType = 'tagLayoutLeft___type',
  TagLayoutLeftIcons = 'tagLayoutLeft___icons',
  TagLayoutLeftIconsId = 'tagLayoutLeft___icons___id',
  TagLayoutLeftIconsTitle = 'tagLayoutLeft___icons___title',
  TagLayoutLeftIconsReference = 'tagLayoutLeft___icons___reference',
  TagLayoutLeftIconsImage = 'tagLayoutLeft___icons___image',
  TagLayoutMain = 'tagLayoutMain',
  TagLayoutMainId = 'tagLayoutMain___id',
  TagLayoutMainTitle = 'tagLayoutMain___title',
  TagLayoutMainType = 'tagLayoutMain___type',
  TagLayoutMainIcons = 'tagLayoutMain___icons',
  TagLayoutMainIconsId = 'tagLayoutMain___icons___id',
  TagLayoutMainIconsTitle = 'tagLayoutMain___icons___title',
  TagLayoutMainIconsReference = 'tagLayoutMain___icons___reference',
  TagLayoutMainIconsImage = 'tagLayoutMain___icons___image',
  TagLayoutRight = 'tagLayoutRight',
  TagLayoutRightId = 'tagLayoutRight___id',
  TagLayoutRightTitle = 'tagLayoutRight___title',
  TagLayoutRightType = 'tagLayoutRight___type',
  TagLayoutRightIcons = 'tagLayoutRight___icons',
  TagLayoutRightIconsId = 'tagLayoutRight___icons___id',
  TagLayoutRightIconsTitle = 'tagLayoutRight___icons___title',
  TagLayoutRightIconsReference = 'tagLayoutRight___icons___reference',
  TagLayoutRightIconsImage = 'tagLayoutRight___icons___image',
  AnswerLayoutLeft = 'answerLayoutLeft',
  AnswerLayoutLeftId = 'answerLayoutLeft___id',
  AnswerLayoutLeftTitle = 'answerLayoutLeft___title',
  AnswerLayoutLeftType = 'answerLayoutLeft___type',
  AnswerLayoutLeftIcons = 'answerLayoutLeft___icons',
  AnswerLayoutLeftIconsId = 'answerLayoutLeft___icons___id',
  AnswerLayoutLeftIconsTitle = 'answerLayoutLeft___icons___title',
  AnswerLayoutLeftIconsReference = 'answerLayoutLeft___icons___reference',
  AnswerLayoutLeftIconsImage = 'answerLayoutLeft___icons___image',
  AnswerLayoutMain = 'answerLayoutMain',
  AnswerLayoutMainId = 'answerLayoutMain___id',
  AnswerLayoutMainTitle = 'answerLayoutMain___title',
  AnswerLayoutMainType = 'answerLayoutMain___type',
  AnswerLayoutMainIcons = 'answerLayoutMain___icons',
  AnswerLayoutMainIconsId = 'answerLayoutMain___icons___id',
  AnswerLayoutMainIconsTitle = 'answerLayoutMain___icons___title',
  AnswerLayoutMainIconsReference = 'answerLayoutMain___icons___reference',
  AnswerLayoutMainIconsImage = 'answerLayoutMain___icons___image',
  AnswerLayoutRight = 'answerLayoutRight',
  AnswerLayoutRightId = 'answerLayoutRight___id',
  AnswerLayoutRightTitle = 'answerLayoutRight___title',
  AnswerLayoutRightType = 'answerLayoutRight___type',
  AnswerLayoutRightIcons = 'answerLayoutRight___icons',
  AnswerLayoutRightIconsId = 'answerLayoutRight___icons___id',
  AnswerLayoutRightIconsTitle = 'answerLayoutRight___icons___title',
  AnswerLayoutRightIconsReference = 'answerLayoutRight___icons___reference',
  AnswerLayoutRightIconsImage = 'answerLayoutRight___icons___image',
  NrOfDisplay = 'nrOfDisplay',
  PvTrackingId = 'pvTrackingId',
  PvViewId = 'pvViewId',
  QuestionnaireYes = 'questionnaire___yes',
  QuestionnaireNo = 'questionnaire___no',
  QuestionnaireTextNo = 'questionnaireTextNo',
  QuestionnaireTextYes = 'questionnaireTextYes',
  Scripts = 'scripts',
  Styles = 'styles',
  Title = 'title',
  Twitter = 'twitter',
  Url = 'url',
  TagCloud = 'tagCloud',
  Categories = 'categories',
  CategoriesId = 'categories___id',
  CategoriesTitle = 'categories___title',
  CategoriesSub = 'categories___sub',
  CategoriesSubId = 'categories___sub___id',
  CategoriesSubTitle = 'categories___sub___title',
  CategoriesSubParent = 'categories___sub___parent',
  CategoriesSubTags = 'categories___sub___tags',
  CategoriesTags = 'categories___tags',
  ExcludeFromFaq = 'excludeFromFAQ',
  ExcludeFromFaqId = 'excludeFromFAQ___id',
  ExcludeFromFaqTitle = 'excludeFromFAQ___title',
  Deploy = 'deploy',
  Informations = 'informations',
  InformationsId = 'informations___id',
  InformationsTitle = 'informations___title',
  Importants = 'importants',
  ImportantsId = 'importants___id',
  ImportantsTitle = 'importants___title',
  Layout = 'layout',
  Id = 'id',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type'
}

export enum TagYamlFieldsEnum {
  Id = 'id',
  Answers = 'answers',
  Relations = 'relations',
  Ignore = 'ignore',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type'
}

export enum SitePageFieldsEnum {
  Id = 'id',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type',
  Path = 'path',
  InternalComponentName = 'internalComponentName',
  Component = 'component',
  ComponentChunkName = 'componentChunkName',
  IsCreatedByStatefulCreatePages = 'isCreatedByStatefulCreatePages',
  ContextParentId = 'context___parentId',
  ContextId = 'context___id',
  ContextParentTitle = 'context___parentTitle',
  ContextTitle = 'context___title',
  ContextTaggedAnswers = 'context___taggedAnswers',
  ContextParentHasAnswers = 'context___parentHasAnswers',
  ContextAsk = 'context___ask',
  ContextAnswerId = 'context___answerId',
  ContextCardId = 'context___cardId',
  ContextAnswers = 'context___answers',
  ContextAnswersId = 'context___answers___id',
  ContextAnswersCardId = 'context___answers___cardId',
  ContextAnswersAsk = 'context___answers___ask',
  ContextAnswersContents = 'context___answers___contents',
  ContextAnswersContentsText = 'context___answers___contents___text',
  ContextAnswersContentsContentFormat = 'context___answers___contents___contentFormat',
  ContextAnswersContentsImage = 'context___answers___contents___image',
  ContextAnswersActionsId = 'context___answers___actions___id',
  ContextAnswersActionsActions = 'context___answers___actions___actions',
  ContextOperatorEscalationKeyword = 'context___operatorEscalationKeyword',
  ContextKarakuriWebRootUrl = 'context___karakuriWebRootUrl',
  ContextInfo = 'context___info',
  ContextRelatedCategories = 'context___relatedCategories',
  ContextRelatedCategoriesId = 'context___relatedCategories___id',
  ContextRelatedCategoriesTitle = 'context___relatedCategories___title',
  ContextRelatedCategoriesSub = 'context___relatedCategories___sub',
  ContextRelatedCategoriesSubParent = 'context___relatedCategories___sub___parent',
  ContextRelatedCategoriesSubId = 'context___relatedCategories___sub___id',
  ContextRelatedCategoriesSubTitle = 'context___relatedCategories___sub___title',
  ContextRelatedCategoriesParent = 'context___relatedCategories___parent',
  PluginCreatorId = 'pluginCreator___id',
  PluginCreatorParentId = 'pluginCreator___parent___id',
  PluginCreatorParentParentId = 'pluginCreator___parent___parent___id',
  PluginCreatorParentParentChildren = 'pluginCreator___parent___parent___children',
  PluginCreatorParentChildren = 'pluginCreator___parent___children',
  PluginCreatorParentChildrenId = 'pluginCreator___parent___children___id',
  PluginCreatorParentChildrenChildren = 'pluginCreator___parent___children___children',
  PluginCreatorParentInternalContent = 'pluginCreator___parent___internal___content',
  PluginCreatorParentInternalContentDigest = 'pluginCreator___parent___internal___contentDigest',
  PluginCreatorParentInternalDescription = 'pluginCreator___parent___internal___description',
  PluginCreatorParentInternalFieldOwners = 'pluginCreator___parent___internal___fieldOwners',
  PluginCreatorParentInternalIgnoreType = 'pluginCreator___parent___internal___ignoreType',
  PluginCreatorParentInternalMediaType = 'pluginCreator___parent___internal___mediaType',
  PluginCreatorParentInternalOwner = 'pluginCreator___parent___internal___owner',
  PluginCreatorParentInternalType = 'pluginCreator___parent___internal___type',
  PluginCreatorChildren = 'pluginCreator___children',
  PluginCreatorChildrenId = 'pluginCreator___children___id',
  PluginCreatorChildrenParentId = 'pluginCreator___children___parent___id',
  PluginCreatorChildrenParentChildren = 'pluginCreator___children___parent___children',
  PluginCreatorChildrenChildren = 'pluginCreator___children___children',
  PluginCreatorChildrenChildrenId = 'pluginCreator___children___children___id',
  PluginCreatorChildrenChildrenChildren = 'pluginCreator___children___children___children',
  PluginCreatorChildrenInternalContent = 'pluginCreator___children___internal___content',
  PluginCreatorChildrenInternalContentDigest = 'pluginCreator___children___internal___contentDigest',
  PluginCreatorChildrenInternalDescription = 'pluginCreator___children___internal___description',
  PluginCreatorChildrenInternalFieldOwners = 'pluginCreator___children___internal___fieldOwners',
  PluginCreatorChildrenInternalIgnoreType = 'pluginCreator___children___internal___ignoreType',
  PluginCreatorChildrenInternalMediaType = 'pluginCreator___children___internal___mediaType',
  PluginCreatorChildrenInternalOwner = 'pluginCreator___children___internal___owner',
  PluginCreatorChildrenInternalType = 'pluginCreator___children___internal___type',
  PluginCreatorInternalContent = 'pluginCreator___internal___content',
  PluginCreatorInternalContentDigest = 'pluginCreator___internal___contentDigest',
  PluginCreatorInternalDescription = 'pluginCreator___internal___description',
  PluginCreatorInternalFieldOwners = 'pluginCreator___internal___fieldOwners',
  PluginCreatorInternalIgnoreType = 'pluginCreator___internal___ignoreType',
  PluginCreatorInternalMediaType = 'pluginCreator___internal___mediaType',
  PluginCreatorInternalOwner = 'pluginCreator___internal___owner',
  PluginCreatorInternalType = 'pluginCreator___internal___type',
  PluginCreatorResolve = 'pluginCreator___resolve',
  PluginCreatorName = 'pluginCreator___name',
  PluginCreatorVersion = 'pluginCreator___version',
  PluginCreatorPluginOptionsFeatures = 'pluginCreator___pluginOptions___features',
  PluginCreatorPluginOptionsName = 'pluginCreator___pluginOptions___name',
  PluginCreatorPluginOptionsPath = 'pluginCreator___pluginOptions___path',
  PluginCreatorPluginOptionsStylesProviderInjectFirst = 'pluginCreator___pluginOptions___stylesProvider___injectFirst',
  PluginCreatorPluginOptionsFontsGoogle = 'pluginCreator___pluginOptions___fonts___google',
  PluginCreatorPluginOptionsModules = 'pluginCreator___pluginOptions___modules',
  PluginCreatorPluginOptionsEndpointUrl = 'pluginCreator___pluginOptions___endpointUrl',
  PluginCreatorPluginOptionsPathCheck = 'pluginCreator___pluginOptions___pathCheck',
  PluginCreatorNodeApIs = 'pluginCreator___nodeAPIs',
  PluginCreatorBrowserApIs = 'pluginCreator___browserAPIs',
  PluginCreatorSsrApIs = 'pluginCreator___ssrAPIs',
  PluginCreatorPluginFilepath = 'pluginCreator___pluginFilepath',
  PluginCreatorPackageJsonName = 'pluginCreator___packageJson___name',
  PluginCreatorPackageJsonDescription = 'pluginCreator___packageJson___description',
  PluginCreatorPackageJsonVersion = 'pluginCreator___packageJson___version',
  PluginCreatorPackageJsonMain = 'pluginCreator___packageJson___main',
  PluginCreatorPackageJsonAuthor = 'pluginCreator___packageJson___author',
  PluginCreatorPackageJsonLicense = 'pluginCreator___packageJson___license',
  PluginCreatorPackageJsonDependencies = 'pluginCreator___packageJson___dependencies',
  PluginCreatorPackageJsonDependenciesName = 'pluginCreator___packageJson___dependencies___name',
  PluginCreatorPackageJsonDependenciesVersion = 'pluginCreator___packageJson___dependencies___version',
  PluginCreatorPackageJsonDevDependencies = 'pluginCreator___packageJson___devDependencies',
  PluginCreatorPackageJsonDevDependenciesName = 'pluginCreator___packageJson___devDependencies___name',
  PluginCreatorPackageJsonDevDependenciesVersion = 'pluginCreator___packageJson___devDependencies___version',
  PluginCreatorPackageJsonPeerDependencies = 'pluginCreator___packageJson___peerDependencies',
  PluginCreatorPackageJsonPeerDependenciesName = 'pluginCreator___packageJson___peerDependencies___name',
  PluginCreatorPackageJsonPeerDependenciesVersion = 'pluginCreator___packageJson___peerDependencies___version',
  PluginCreatorPackageJsonKeywords = 'pluginCreator___packageJson___keywords',
  PluginCreatorId = 'pluginCreatorId',
  ComponentPath = 'componentPath'
}

export enum SitePluginFieldsEnum {
  Id = 'id',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type',
  Resolve = 'resolve',
  Name = 'name',
  Version = 'version',
  PluginOptionsFeatures = 'pluginOptions___features',
  PluginOptionsName = 'pluginOptions___name',
  PluginOptionsPath = 'pluginOptions___path',
  PluginOptionsStylesProviderInjectFirst = 'pluginOptions___stylesProvider___injectFirst',
  PluginOptionsFontsGoogle = 'pluginOptions___fonts___google',
  PluginOptionsFontsGoogleFamily = 'pluginOptions___fonts___google___family',
  PluginOptionsFontsGoogleVariants = 'pluginOptions___fonts___google___variants',
  PluginOptionsModules = 'pluginOptions___modules',
  PluginOptionsEndpointUrl = 'pluginOptions___endpointUrl',
  PluginOptionsPathCheck = 'pluginOptions___pathCheck',
  NodeApIs = 'nodeAPIs',
  BrowserApIs = 'browserAPIs',
  SsrApIs = 'ssrAPIs',
  PluginFilepath = 'pluginFilepath',
  PackageJsonName = 'packageJson___name',
  PackageJsonDescription = 'packageJson___description',
  PackageJsonVersion = 'packageJson___version',
  PackageJsonMain = 'packageJson___main',
  PackageJsonAuthor = 'packageJson___author',
  PackageJsonLicense = 'packageJson___license',
  PackageJsonDependencies = 'packageJson___dependencies',
  PackageJsonDependenciesName = 'packageJson___dependencies___name',
  PackageJsonDependenciesVersion = 'packageJson___dependencies___version',
  PackageJsonDevDependencies = 'packageJson___devDependencies',
  PackageJsonDevDependenciesName = 'packageJson___devDependencies___name',
  PackageJsonDevDependenciesVersion = 'packageJson___devDependencies___version',
  PackageJsonPeerDependencies = 'packageJson___peerDependencies',
  PackageJsonPeerDependenciesName = 'packageJson___peerDependencies___name',
  PackageJsonPeerDependenciesVersion = 'packageJson___peerDependencies___version',
  PackageJsonKeywords = 'packageJson___keywords'
}

export enum SiteFieldsEnum {
  Id = 'id',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type',
  SiteMetadataTitle = 'siteMetadata___title',
  SiteMetadataDescription = 'siteMetadata___description',
  Port = 'port',
  Host = 'host',
  Polyfill = 'polyfill',
  PathPrefix = 'pathPrefix',
  BuildTime = 'buildTime'
}

export enum DirectoryFieldsEnum {
  Id = 'id',
  ParentId = 'parent___id',
  ParentParentId = 'parent___parent___id',
  ParentParentParentId = 'parent___parent___parent___id',
  ParentParentParentChildren = 'parent___parent___parent___children',
  ParentParentChildren = 'parent___parent___children',
  ParentParentChildrenId = 'parent___parent___children___id',
  ParentParentChildrenChildren = 'parent___parent___children___children',
  ParentParentInternalContent = 'parent___parent___internal___content',
  ParentParentInternalContentDigest = 'parent___parent___internal___contentDigest',
  ParentParentInternalDescription = 'parent___parent___internal___description',
  ParentParentInternalFieldOwners = 'parent___parent___internal___fieldOwners',
  ParentParentInternalIgnoreType = 'parent___parent___internal___ignoreType',
  ParentParentInternalMediaType = 'parent___parent___internal___mediaType',
  ParentParentInternalOwner = 'parent___parent___internal___owner',
  ParentParentInternalType = 'parent___parent___internal___type',
  ParentChildren = 'parent___children',
  ParentChildrenId = 'parent___children___id',
  ParentChildrenParentId = 'parent___children___parent___id',
  ParentChildrenParentChildren = 'parent___children___parent___children',
  ParentChildrenChildren = 'parent___children___children',
  ParentChildrenChildrenId = 'parent___children___children___id',
  ParentChildrenChildrenChildren = 'parent___children___children___children',
  ParentChildrenInternalContent = 'parent___children___internal___content',
  ParentChildrenInternalContentDigest = 'parent___children___internal___contentDigest',
  ParentChildrenInternalDescription = 'parent___children___internal___description',
  ParentChildrenInternalFieldOwners = 'parent___children___internal___fieldOwners',
  ParentChildrenInternalIgnoreType = 'parent___children___internal___ignoreType',
  ParentChildrenInternalMediaType = 'parent___children___internal___mediaType',
  ParentChildrenInternalOwner = 'parent___children___internal___owner',
  ParentChildrenInternalType = 'parent___children___internal___type',
  ParentInternalContent = 'parent___internal___content',
  ParentInternalContentDigest = 'parent___internal___contentDigest',
  ParentInternalDescription = 'parent___internal___description',
  ParentInternalFieldOwners = 'parent___internal___fieldOwners',
  ParentInternalIgnoreType = 'parent___internal___ignoreType',
  ParentInternalMediaType = 'parent___internal___mediaType',
  ParentInternalOwner = 'parent___internal___owner',
  ParentInternalType = 'parent___internal___type',
  Children = 'children',
  ChildrenId = 'children___id',
  ChildrenParentId = 'children___parent___id',
  ChildrenParentParentId = 'children___parent___parent___id',
  ChildrenParentParentChildren = 'children___parent___parent___children',
  ChildrenParentChildren = 'children___parent___children',
  ChildrenParentChildrenId = 'children___parent___children___id',
  ChildrenParentChildrenChildren = 'children___parent___children___children',
  ChildrenParentInternalContent = 'children___parent___internal___content',
  ChildrenParentInternalContentDigest = 'children___parent___internal___contentDigest',
  ChildrenParentInternalDescription = 'children___parent___internal___description',
  ChildrenParentInternalFieldOwners = 'children___parent___internal___fieldOwners',
  ChildrenParentInternalIgnoreType = 'children___parent___internal___ignoreType',
  ChildrenParentInternalMediaType = 'children___parent___internal___mediaType',
  ChildrenParentInternalOwner = 'children___parent___internal___owner',
  ChildrenParentInternalType = 'children___parent___internal___type',
  ChildrenChildren = 'children___children',
  ChildrenChildrenId = 'children___children___id',
  ChildrenChildrenParentId = 'children___children___parent___id',
  ChildrenChildrenParentChildren = 'children___children___parent___children',
  ChildrenChildrenChildren = 'children___children___children',
  ChildrenChildrenChildrenId = 'children___children___children___id',
  ChildrenChildrenChildrenChildren = 'children___children___children___children',
  ChildrenChildrenInternalContent = 'children___children___internal___content',
  ChildrenChildrenInternalContentDigest = 'children___children___internal___contentDigest',
  ChildrenChildrenInternalDescription = 'children___children___internal___description',
  ChildrenChildrenInternalFieldOwners = 'children___children___internal___fieldOwners',
  ChildrenChildrenInternalIgnoreType = 'children___children___internal___ignoreType',
  ChildrenChildrenInternalMediaType = 'children___children___internal___mediaType',
  ChildrenChildrenInternalOwner = 'children___children___internal___owner',
  ChildrenChildrenInternalType = 'children___children___internal___type',
  ChildrenInternalContent = 'children___internal___content',
  ChildrenInternalContentDigest = 'children___internal___contentDigest',
  ChildrenInternalDescription = 'children___internal___description',
  ChildrenInternalFieldOwners = 'children___internal___fieldOwners',
  ChildrenInternalIgnoreType = 'children___internal___ignoreType',
  ChildrenInternalMediaType = 'children___internal___mediaType',
  ChildrenInternalOwner = 'children___internal___owner',
  ChildrenInternalType = 'children___internal___type',
  InternalContent = 'internal___content',
  InternalContentDigest = 'internal___contentDigest',
  InternalDescription = 'internal___description',
  InternalFieldOwners = 'internal___fieldOwners',
  InternalIgnoreType = 'internal___ignoreType',
  InternalMediaType = 'internal___mediaType',
  InternalOwner = 'internal___owner',
  InternalType = 'internal___type',
  SourceInstanceName = 'sourceInstanceName',
  AbsolutePath = 'absolutePath',
  RelativePath = 'relativePath',
  Extension = 'extension',
  Size = 'size',
  PrettySize = 'prettySize',
  ModifiedTime = 'modifiedTime',
  AccessTime = 'accessTime',
  ChangeTime = 'changeTime',
  BirthTime = 'birthTime',
  Root = 'root',
  Dir = 'dir',
  Base = 'base',
  Ext = 'ext',
  Name = 'name',
  RelativeDirectory = 'relativeDirectory',
  Dev = 'dev',
  Mode = 'mode',
  Nlink = 'nlink',
  Uid = 'uid',
  Gid = 'gid',
  Rdev = 'rdev',
  Blksize = 'blksize',
  Ino = 'ino',
  Blocks = 'blocks',
  AtimeMs = 'atimeMs',
  MtimeMs = 'mtimeMs',
  CtimeMs = 'ctimeMs',
  BirthtimeMs = 'birthtimeMs',
  Atime = 'atime',
  Mtime = 'mtime',
  Ctime = 'ctime',
  Birthtime = 'birthtime'
}

/** A date string, such as 2007-12-03, compliant with the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
export type Date = any

/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type Json = any

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Interfaces
// ====================================================

/** Node Interface */
export interface Node {
  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal
}

// ====================================================
// Types
// ====================================================

export interface Query {
  file?: Maybe<File>

  allFile: FileConnection

  imageSharp?: Maybe<ImageSharp>

  allImageSharp: ImageSharpConnection

  answerYaml?: Maybe<AnswerYaml>

  allAnswerYaml: AnswerYamlConnection

  categoryYaml?: Maybe<CategoryYaml>

  allCategoryYaml: CategoryYamlConnection

  importantYaml?: Maybe<ImportantYaml>

  allImportantYaml: ImportantYamlConnection

  informationYaml?: Maybe<InformationYaml>

  allInformationYaml: InformationYamlConnection

  settingYaml?: Maybe<SettingYaml>

  allSettingYaml: SettingYamlConnection

  tagYaml?: Maybe<TagYaml>

  allTagYaml: TagYamlConnection

  sitePage?: Maybe<SitePage>

  allSitePage: SitePageConnection

  sitePlugin?: Maybe<SitePlugin>

  allSitePlugin: SitePluginConnection

  site?: Maybe<Site>

  allSite: SiteConnection

  directory?: Maybe<Directory>

  allDirectory: DirectoryConnection
}

export interface File extends Node {
  birthtime?: Maybe<Date>

  birthtimeMs?: Maybe<number>

  sourceInstanceName?: Maybe<string>

  absolutePath?: Maybe<string>

  relativePath?: Maybe<string>

  extension?: Maybe<string>

  size?: Maybe<number>

  prettySize?: Maybe<string>

  modifiedTime?: Maybe<Date>

  accessTime?: Maybe<Date>

  changeTime?: Maybe<Date>

  birthTime?: Maybe<Date>

  root?: Maybe<string>

  dir?: Maybe<string>

  base?: Maybe<string>

  ext?: Maybe<string>

  name?: Maybe<string>

  relativeDirectory?: Maybe<string>

  dev?: Maybe<number>

  mode?: Maybe<number>

  nlink?: Maybe<number>

  uid?: Maybe<number>

  gid?: Maybe<number>

  rdev?: Maybe<number>

  blksize?: Maybe<number>

  ino?: Maybe<number>

  blocks?: Maybe<number>

  atimeMs?: Maybe<number>

  mtimeMs?: Maybe<number>

  ctimeMs?: Maybe<number>

  atime?: Maybe<Date>

  mtime?: Maybe<Date>

  ctime?: Maybe<Date>
  /** Copy file to static directory and return public url to it */
  publicURL?: Maybe<string>

  childImageSharp?: Maybe<ImageSharp>

  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal

  childrenAnswerYaml?: Maybe<(Maybe<AnswerYaml>)[]>

  childrenCategoryYaml?: Maybe<(Maybe<CategoryYaml>)[]>

  childrenTagYaml?: Maybe<(Maybe<TagYaml>)[]>

  childrenSettingYaml?: Maybe<(Maybe<SettingYaml>)[]>
}

export interface Internal {
  content?: Maybe<string>

  contentDigest: string

  description?: Maybe<string>

  fieldOwners?: Maybe<(Maybe<string>)[]>

  ignoreType?: Maybe<boolean>

  mediaType?: Maybe<string>

  owner: string

  type: string
}

export interface ImageSharp extends Node {
  fixed?: Maybe<ImageSharpFixed>

  resolutions?: Maybe<ImageSharpResolutions>

  fluid?: Maybe<ImageSharpFluid>

  sizes?: Maybe<ImageSharpSizes>

  original?: Maybe<ImageSharpOriginal>

  resize?: Maybe<ImageSharpResize>

  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal
}

export interface ImageSharpFixed {
  base64?: Maybe<string>

  tracedSVG?: Maybe<string>

  aspectRatio?: Maybe<number>

  width?: Maybe<number>

  height?: Maybe<number>

  src?: Maybe<string>

  srcSet?: Maybe<string>

  srcWebp?: Maybe<string>

  srcSetWebp?: Maybe<string>

  originalName?: Maybe<string>
}

export interface ImageSharpResolutions {
  base64?: Maybe<string>

  tracedSVG?: Maybe<string>

  aspectRatio?: Maybe<number>

  width?: Maybe<number>

  height?: Maybe<number>

  src?: Maybe<string>

  srcSet?: Maybe<string>

  srcWebp?: Maybe<string>

  srcSetWebp?: Maybe<string>

  originalName?: Maybe<string>
}

export interface ImageSharpFluid {
  base64?: Maybe<string>

  tracedSVG?: Maybe<string>

  aspectRatio?: Maybe<number>

  src?: Maybe<string>

  srcSet?: Maybe<string>

  srcWebp?: Maybe<string>

  srcSetWebp?: Maybe<string>

  sizes?: Maybe<string>

  originalImg?: Maybe<string>

  originalName?: Maybe<string>

  presentationWidth?: Maybe<number>

  presentationHeight?: Maybe<number>
}

export interface ImageSharpSizes {
  base64?: Maybe<string>

  tracedSVG?: Maybe<string>

  aspectRatio?: Maybe<number>

  src?: Maybe<string>

  srcSet?: Maybe<string>

  srcWebp?: Maybe<string>

  srcSetWebp?: Maybe<string>

  sizes?: Maybe<string>

  originalImg?: Maybe<string>

  originalName?: Maybe<string>

  presentationWidth?: Maybe<number>

  presentationHeight?: Maybe<number>
}

export interface ImageSharpOriginal {
  width?: Maybe<number>

  height?: Maybe<number>

  src?: Maybe<string>
}

export interface ImageSharpResize {
  src?: Maybe<string>

  tracedSVG?: Maybe<string>

  width?: Maybe<number>

  height?: Maybe<number>

  aspectRatio?: Maybe<number>

  originalName?: Maybe<string>
}

export interface AnswerYaml extends Node {
  ignore?: Maybe<boolean>

  id: string

  cardId?: Maybe<string>

  ask?: Maybe<string>

  contents?: Maybe<(Maybe<AnswerYamlContent>)[]>

  actions?: Maybe<AnswerYamlActions>

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal
}

export interface AnswerYamlContent {
  text?: Maybe<string>

  contentFormat?: Maybe<string>

  image?: Maybe<string>
}

export interface AnswerYamlActions {
  id: string

  image?: Maybe<string>

  actions?: Maybe<(Maybe<AnswerYamlActionsAction>)[]>
}

export interface AnswerYamlActionsAction {
  id: string

  label?: Maybe<string>

  external?: Maybe<boolean>

  actionId?: Maybe<string>
}

export interface CategoryYaml extends Node {
  ignore?: Maybe<boolean>

  id: string

  title?: Maybe<string>

  parent?: Maybe<Node>

  sub?: Maybe<(Maybe<CategoryYamlSub>)[]>

  tags?: Maybe<(Maybe<string>)[]>

  children: Node[]

  internal: Internal
}

export interface CategoryYamlSub {
  id: string

  title?: Maybe<string>

  parent?: Maybe<string>

  tags?: Maybe<(Maybe<string>)[]>
}

export interface TagYaml extends Node {
  id: string

  answers?: Maybe<(Maybe<string>)[]>

  relations?: Maybe<(Maybe<string>)[]>

  ignore?: Maybe<boolean>

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal
}

export interface SettingYaml extends Node {
  botId?: Maybe<string>

  customContents?: Maybe<(Maybe<SettingYamlCustomContent>)[]>

  deploymentHostName?: Maybe<string>

  description?: Maybe<string>

  enabled?: Maybe<boolean>

  excludeDuplication?: Maybe<boolean>

  excludes?: Maybe<(Maybe<SettingYamlExclude>)[]>

  facebook?: Maybe<string>

  fallbackUrl?: Maybe<string>

  faviconUrl?: Maybe<string>

  footer?: Maybe<string>

  gtags?: Maybe<(Maybe<string>)[]>

  header?: Maybe<string>

  headerImageAltText?: Maybe<string>

  headerImageLinkUrl?: Maybe<string>

  headerImageUrl?: Maybe<string>

  ignore?: Maybe<boolean>

  karakuriWebRootUrl?: Maybe<string>

  language?: Maybe<string>

  layoutLeft?: Maybe<(Maybe<Layout>)[]>

  layoutMain?: Maybe<(Maybe<Layout>)[]>

  layoutRight?: Maybe<(Maybe<Layout>)[]>

  tagLayoutLeft?: Maybe<(Maybe<Layout>)[]>

  tagLayoutMain?: Maybe<(Maybe<Layout>)[]>

  tagLayoutRight?: Maybe<(Maybe<Layout>)[]>

  answerLayoutLeft?: Maybe<(Maybe<Layout>)[]>

  answerLayoutMain?: Maybe<(Maybe<Layout>)[]>

  answerLayoutRight?: Maybe<(Maybe<Layout>)[]>

  nrOfDisplay?: Maybe<number>

  pvTrackingId?: Maybe<string>

  pvViewId?: Maybe<string>

  questionnaire?: Maybe<SettingYamlQuestionnaire>

  questionnaireTextNo?: Maybe<string>

  questionnaireTextYes?: Maybe<string>

  scripts?: Maybe<(Maybe<string>)[]>

  styles?: Maybe<(Maybe<string>)[]>

  title?: Maybe<string>

  twitter?: Maybe<string>

  url?: Maybe<string>

  tagCloud?: Maybe<boolean>

  categories?: Maybe<(Maybe<SettingYamlCategories>)[]>

  excludeFromFAQ?: Maybe<(Maybe<SettingYamlExcludeFromFaq>)[]>

  deploy?: Maybe<boolean>

  informations?: Maybe<(Maybe<SettingYamlInformations>)[]>

  importants?: Maybe<(Maybe<SettingYamlImportants>)[]>

  layout?: Maybe<string>

  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal
}

export interface SettingYamlCustomContent {
  id: string

  type?: Maybe<string>

  title?: Maybe<string>

  parts?: Maybe<(Maybe<SettingYamlCustomContentPart>)[]>
}

export interface SettingYamlCustomContentPart {
  id: string

  title?: Maybe<string>

  image?: Maybe<string>
}

export interface SettingYamlExclude {
  id: string

  title?: Maybe<string>
}

export interface Layout {
  id: string

  title?: Maybe<string>

  type: LayoutType

  icons?: Maybe<(Maybe<LayoutIcon>)[]>
}

export interface LayoutIcon {
  id: string

  title?: Maybe<string>

  reference?: Maybe<string>

  image?: Maybe<string>
}

export interface SettingYamlQuestionnaire {
  yes?: Maybe<string>

  no?: Maybe<string>
}

export interface SettingYamlCategories {
  id?: Maybe<string>

  title?: Maybe<string>

  sub?: Maybe<(Maybe<SettingYamlCategoriesSub>)[]>

  tags?: Maybe<(Maybe<string>)[]>
}

export interface SettingYamlCategoriesSub {
  id?: Maybe<string>

  title?: Maybe<string>

  parent?: Maybe<string>

  tags?: Maybe<(Maybe<string>)[]>
}

export interface SettingYamlExcludeFromFaq {
  id?: Maybe<string>

  title?: Maybe<string>
}

export interface SettingYamlInformations {
  id?: Maybe<string>

  title?: Maybe<string>
}

export interface SettingYamlImportants {
  id?: Maybe<string>

  title?: Maybe<string>
}

export interface FileConnection {
  totalCount: number

  edges: FileEdge[]

  nodes: File[]

  pageInfo: PageInfo

  distinct: string[]

  group: FileGroupConnection[]
}

export interface FileEdge {
  next?: Maybe<File>

  node: File

  previous?: Maybe<File>
}

export interface PageInfo {
  currentPage: number

  hasPreviousPage: boolean

  hasNextPage: boolean

  itemCount: number

  pageCount: number

  perPage?: Maybe<number>
}

export interface FileGroupConnection {
  totalCount: number

  edges: FileEdge[]

  nodes: File[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface ImageSharpConnection {
  totalCount: number

  edges: ImageSharpEdge[]

  nodes: ImageSharp[]

  pageInfo: PageInfo

  distinct: string[]

  group: ImageSharpGroupConnection[]
}

export interface ImageSharpEdge {
  next?: Maybe<ImageSharp>

  node: ImageSharp

  previous?: Maybe<ImageSharp>
}

export interface ImageSharpGroupConnection {
  totalCount: number

  edges: ImageSharpEdge[]

  nodes: ImageSharp[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface AnswerYamlConnection {
  totalCount: number

  edges: AnswerYamlEdge[]

  nodes: AnswerYaml[]

  pageInfo: PageInfo

  distinct: string[]

  group: AnswerYamlGroupConnection[]
}

export interface AnswerYamlEdge {
  next?: Maybe<AnswerYaml>

  node: AnswerYaml

  previous?: Maybe<AnswerYaml>
}

export interface AnswerYamlGroupConnection {
  totalCount: number

  edges: AnswerYamlEdge[]

  nodes: AnswerYaml[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface CategoryYamlConnection {
  totalCount: number

  edges: CategoryYamlEdge[]

  nodes: CategoryYaml[]

  pageInfo: PageInfo

  distinct: string[]

  group: CategoryYamlGroupConnection[]
}

export interface CategoryYamlEdge {
  next?: Maybe<CategoryYaml>

  node: CategoryYaml

  previous?: Maybe<CategoryYaml>
}

export interface CategoryYamlGroupConnection {
  totalCount: number

  edges: CategoryYamlEdge[]

  nodes: CategoryYaml[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface ImportantYaml extends Node {
  id: string

  title?: Maybe<string>

  ignore?: Maybe<boolean>

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal
}

export interface ImportantYamlConnection {
  totalCount: number

  edges: ImportantYamlEdge[]

  nodes: ImportantYaml[]

  pageInfo: PageInfo

  distinct: string[]

  group: ImportantYamlGroupConnection[]
}

export interface ImportantYamlEdge {
  next?: Maybe<ImportantYaml>

  node: ImportantYaml

  previous?: Maybe<ImportantYaml>
}

export interface ImportantYamlGroupConnection {
  totalCount: number

  edges: ImportantYamlEdge[]

  nodes: ImportantYaml[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface InformationYaml extends Node {
  id: string

  title?: Maybe<string>

  ignore?: Maybe<boolean>

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal
}

export interface InformationYamlConnection {
  totalCount: number

  edges: InformationYamlEdge[]

  nodes: InformationYaml[]

  pageInfo: PageInfo

  distinct: string[]

  group: InformationYamlGroupConnection[]
}

export interface InformationYamlEdge {
  next?: Maybe<InformationYaml>

  node: InformationYaml

  previous?: Maybe<InformationYaml>
}

export interface InformationYamlGroupConnection {
  totalCount: number

  edges: InformationYamlEdge[]

  nodes: InformationYaml[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface SettingYamlConnection {
  totalCount: number

  edges: SettingYamlEdge[]

  nodes: SettingYaml[]

  pageInfo: PageInfo

  distinct: string[]

  group: SettingYamlGroupConnection[]
}

export interface SettingYamlEdge {
  next?: Maybe<SettingYaml>

  node: SettingYaml

  previous?: Maybe<SettingYaml>
}

export interface SettingYamlGroupConnection {
  totalCount: number

  edges: SettingYamlEdge[]

  nodes: SettingYaml[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface TagYamlConnection {
  totalCount: number

  edges: TagYamlEdge[]

  nodes: TagYaml[]

  pageInfo: PageInfo

  distinct: string[]

  group: TagYamlGroupConnection[]
}

export interface TagYamlEdge {
  next?: Maybe<TagYaml>

  node: TagYaml

  previous?: Maybe<TagYaml>
}

export interface TagYamlGroupConnection {
  totalCount: number

  edges: TagYamlEdge[]

  nodes: TagYaml[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface SitePage extends Node {
  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal

  path?: Maybe<string>

  internalComponentName?: Maybe<string>

  component?: Maybe<string>

  componentChunkName?: Maybe<string>

  isCreatedByStatefulCreatePages?: Maybe<boolean>

  context?: Maybe<SitePageContext>

  pluginCreator?: Maybe<SitePlugin>

  pluginCreatorId?: Maybe<string>

  componentPath?: Maybe<string>
}

export interface SitePageContext {
  parentId?: Maybe<string>

  id?: Maybe<string>

  parentTitle?: Maybe<string>

  title?: Maybe<string>

  taggedAnswers?: Maybe<(Maybe<string>)[]>

  parentHasAnswers?: Maybe<boolean>

  ask?: Maybe<string>

  answerId?: Maybe<string>

  cardId?: Maybe<string>

  answers?: Maybe<(Maybe<SitePageContextAnswers>)[]>

  operatorEscalationKeyword?: Maybe<string>

  karakuriWebRootUrl?: Maybe<string>

  info?: Maybe<boolean>

  relatedCategories?: Maybe<(Maybe<SitePageContextRelatedCategories>)[]>
}

export interface SitePageContextAnswers {
  id?: Maybe<string>

  cardId?: Maybe<string>

  ask?: Maybe<string>

  contents?: Maybe<(Maybe<SitePageContextAnswersContents>)[]>

  actions?: Maybe<SitePageContextAnswersActions>
}

export interface SitePageContextAnswersContents {
  text?: Maybe<string>

  contentFormat?: Maybe<string>

  image?: Maybe<string>
}

export interface SitePageContextAnswersActions {
  id?: Maybe<string>

  actions?: Maybe<(Maybe<SitePageContextAnswersActionsActions>)[]>
}

export interface SitePageContextAnswersActionsActions {
  id?: Maybe<string>

  label?: Maybe<string>

  external?: Maybe<boolean>

  actionId?: Maybe<string>
}

export interface SitePageContextRelatedCategories {
  id?: Maybe<string>

  title?: Maybe<string>

  sub?: Maybe<(Maybe<SitePageContextRelatedCategoriesSub>)[]>

  parent?: Maybe<string>
}

export interface SitePageContextRelatedCategoriesSub {
  parent?: Maybe<string>

  id?: Maybe<string>

  title?: Maybe<string>
}

export interface SitePlugin extends Node {
  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal

  resolve?: Maybe<string>

  name?: Maybe<string>

  version?: Maybe<string>

  pluginOptions?: Maybe<SitePluginPluginOptions>

  nodeAPIs?: Maybe<(Maybe<string>)[]>

  browserAPIs?: Maybe<(Maybe<string>)[]>

  ssrAPIs?: Maybe<(Maybe<string>)[]>

  pluginFilepath?: Maybe<string>

  packageJson?: Maybe<SitePluginPackageJson>
}

export interface SitePluginPluginOptions {
  features?: Maybe<(Maybe<string>)[]>

  name?: Maybe<string>

  path?: Maybe<string>

  stylesProvider?: Maybe<SitePluginPluginOptionsStylesProvider>

  fonts?: Maybe<SitePluginPluginOptionsFonts>

  modules?: Maybe<(Maybe<string>)[]>

  endpointUrl?: Maybe<string>

  pathCheck?: Maybe<boolean>
}

export interface SitePluginPluginOptionsStylesProvider {
  injectFirst?: Maybe<boolean>
}

export interface SitePluginPluginOptionsFonts {
  google?: Maybe<(Maybe<SitePluginPluginOptionsFontsGoogle>)[]>
}

export interface SitePluginPluginOptionsFontsGoogle {
  family?: Maybe<string>

  variants?: Maybe<(Maybe<string>)[]>
}

export interface SitePluginPackageJson {
  name?: Maybe<string>

  description?: Maybe<string>

  version?: Maybe<string>

  main?: Maybe<string>

  author?: Maybe<string>

  license?: Maybe<string>

  dependencies?: Maybe<(Maybe<SitePluginPackageJsonDependencies>)[]>

  devDependencies?: Maybe<(Maybe<SitePluginPackageJsonDevDependencies>)[]>

  peerDependencies?: Maybe<(Maybe<SitePluginPackageJsonPeerDependencies>)[]>

  keywords?: Maybe<(Maybe<string>)[]>
}

export interface SitePluginPackageJsonDependencies {
  name?: Maybe<string>

  version?: Maybe<string>
}

export interface SitePluginPackageJsonDevDependencies {
  name?: Maybe<string>

  version?: Maybe<string>
}

export interface SitePluginPackageJsonPeerDependencies {
  name?: Maybe<string>

  version?: Maybe<string>
}

export interface SitePageConnection {
  totalCount: number

  edges: SitePageEdge[]

  nodes: SitePage[]

  pageInfo: PageInfo

  distinct: string[]

  group: SitePageGroupConnection[]
}

export interface SitePageEdge {
  next?: Maybe<SitePage>

  node: SitePage

  previous?: Maybe<SitePage>
}

export interface SitePageGroupConnection {
  totalCount: number

  edges: SitePageEdge[]

  nodes: SitePage[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface SitePluginConnection {
  totalCount: number

  edges: SitePluginEdge[]

  nodes: SitePlugin[]

  pageInfo: PageInfo

  distinct: string[]

  group: SitePluginGroupConnection[]
}

export interface SitePluginEdge {
  next?: Maybe<SitePlugin>

  node: SitePlugin

  previous?: Maybe<SitePlugin>
}

export interface SitePluginGroupConnection {
  totalCount: number

  edges: SitePluginEdge[]

  nodes: SitePlugin[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface Site extends Node {
  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal

  siteMetadata?: Maybe<SiteSiteMetadata>

  port?: Maybe<number>

  host?: Maybe<string>

  polyfill?: Maybe<boolean>

  pathPrefix?: Maybe<string>

  buildTime?: Maybe<Date>
}

export interface SiteSiteMetadata {
  title?: Maybe<string>

  description?: Maybe<string>
}

export interface SiteConnection {
  totalCount: number

  edges: SiteEdge[]

  nodes: Site[]

  pageInfo: PageInfo

  distinct: string[]

  group: SiteGroupConnection[]
}

export interface SiteEdge {
  next?: Maybe<Site>

  node: Site

  previous?: Maybe<Site>
}

export interface SiteGroupConnection {
  totalCount: number

  edges: SiteEdge[]

  nodes: Site[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

export interface Directory extends Node {
  id: string

  parent?: Maybe<Node>

  children: Node[]

  internal: Internal

  sourceInstanceName?: Maybe<string>

  absolutePath?: Maybe<string>

  relativePath?: Maybe<string>

  extension?: Maybe<string>

  size?: Maybe<number>

  prettySize?: Maybe<string>

  modifiedTime?: Maybe<Date>

  accessTime?: Maybe<Date>

  changeTime?: Maybe<Date>

  birthTime?: Maybe<Date>

  root?: Maybe<string>

  dir?: Maybe<string>

  base?: Maybe<string>

  ext?: Maybe<string>

  name?: Maybe<string>

  relativeDirectory?: Maybe<string>

  dev?: Maybe<number>

  mode?: Maybe<number>

  nlink?: Maybe<number>

  uid?: Maybe<number>

  gid?: Maybe<number>

  rdev?: Maybe<number>

  blksize?: Maybe<number>

  ino?: Maybe<number>

  blocks?: Maybe<number>

  atimeMs?: Maybe<number>

  mtimeMs?: Maybe<number>

  ctimeMs?: Maybe<number>

  birthtimeMs?: Maybe<number>

  atime?: Maybe<Date>

  mtime?: Maybe<Date>

  ctime?: Maybe<Date>

  birthtime?: Maybe<Date>
}

export interface DirectoryConnection {
  totalCount: number

  edges: DirectoryEdge[]

  nodes: Directory[]

  pageInfo: PageInfo

  distinct: string[]

  group: DirectoryGroupConnection[]
}

export interface DirectoryEdge {
  next?: Maybe<Directory>

  node: Directory

  previous?: Maybe<Directory>
}

export interface DirectoryGroupConnection {
  totalCount: number

  edges: DirectoryEdge[]

  nodes: Directory[]

  pageInfo: PageInfo

  field: string

  fieldValue?: Maybe<string>
}

// ====================================================
// Arguments
// ====================================================

export interface FileQueryArgs {
  birthtime?: Maybe<DateQueryOperatorInput>

  birthtimeMs?: Maybe<FloatQueryOperatorInput>

  sourceInstanceName?: Maybe<StringQueryOperatorInput>

  absolutePath?: Maybe<StringQueryOperatorInput>

  relativePath?: Maybe<StringQueryOperatorInput>

  extension?: Maybe<StringQueryOperatorInput>

  size?: Maybe<IntQueryOperatorInput>

  prettySize?: Maybe<StringQueryOperatorInput>

  modifiedTime?: Maybe<DateQueryOperatorInput>

  accessTime?: Maybe<DateQueryOperatorInput>

  changeTime?: Maybe<DateQueryOperatorInput>

  birthTime?: Maybe<DateQueryOperatorInput>

  root?: Maybe<StringQueryOperatorInput>

  dir?: Maybe<StringQueryOperatorInput>

  base?: Maybe<StringQueryOperatorInput>

  ext?: Maybe<StringQueryOperatorInput>

  name?: Maybe<StringQueryOperatorInput>

  relativeDirectory?: Maybe<StringQueryOperatorInput>

  dev?: Maybe<IntQueryOperatorInput>

  mode?: Maybe<IntQueryOperatorInput>

  nlink?: Maybe<IntQueryOperatorInput>

  uid?: Maybe<IntQueryOperatorInput>

  gid?: Maybe<IntQueryOperatorInput>

  rdev?: Maybe<IntQueryOperatorInput>

  blksize?: Maybe<IntQueryOperatorInput>

  ino?: Maybe<IntQueryOperatorInput>

  blocks?: Maybe<IntQueryOperatorInput>

  atimeMs?: Maybe<FloatQueryOperatorInput>

  mtimeMs?: Maybe<FloatQueryOperatorInput>

  ctimeMs?: Maybe<FloatQueryOperatorInput>

  atime?: Maybe<DateQueryOperatorInput>

  mtime?: Maybe<DateQueryOperatorInput>

  ctime?: Maybe<DateQueryOperatorInput>

  publicURL?: Maybe<StringQueryOperatorInput>

  childImageSharp?: Maybe<ImageSharpFilterInput>

  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  childrenAnswerYaml?: Maybe<AnswerYamlFilterListInput>

  childrenCategoryYaml?: Maybe<CategoryYamlFilterListInput>

  childrenTagYaml?: Maybe<TagYamlFilterListInput>

  childrenSettingYaml?: Maybe<SettingYamlFilterListInput>
}
export interface AllFileQueryArgs {
  filter?: Maybe<FileFilterInput>

  sort?: Maybe<FileSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface ImageSharpQueryArgs {
  fixed?: Maybe<ImageSharpFixedFilterInput>

  resolutions?: Maybe<ImageSharpResolutionsFilterInput>

  fluid?: Maybe<ImageSharpFluidFilterInput>

  sizes?: Maybe<ImageSharpSizesFilterInput>

  original?: Maybe<ImageSharpOriginalFilterInput>

  resize?: Maybe<ImageSharpResizeFilterInput>

  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}
export interface AllImageSharpQueryArgs {
  filter?: Maybe<ImageSharpFilterInput>

  sort?: Maybe<ImageSharpSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface AnswerYamlQueryArgs {
  ignore?: Maybe<BooleanQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  cardId?: Maybe<StringQueryOperatorInput>

  ask?: Maybe<StringQueryOperatorInput>

  contents?: Maybe<AnswerYamlContentFilterListInput>

  actions?: Maybe<AnswerYamlActionsFilterInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}
export interface AllAnswerYamlQueryArgs {
  filter?: Maybe<AnswerYamlFilterInput>

  sort?: Maybe<AnswerYamlSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface CategoryYamlQueryArgs {
  ignore?: Maybe<BooleanQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  sub?: Maybe<CategoryYamlSubFilterListInput>

  tags?: Maybe<StringQueryOperatorInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}
export interface AllCategoryYamlQueryArgs {
  filter?: Maybe<CategoryYamlFilterInput>

  sort?: Maybe<CategoryYamlSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface ImportantYamlQueryArgs {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}
export interface AllImportantYamlQueryArgs {
  filter?: Maybe<ImportantYamlFilterInput>

  sort?: Maybe<ImportantYamlSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface InformationYamlQueryArgs {
  id?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}
export interface AllInformationYamlQueryArgs {
  filter?: Maybe<InformationYamlFilterInput>

  sort?: Maybe<InformationYamlSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface SettingYamlQueryArgs {
  botId?: Maybe<StringQueryOperatorInput>

  customContents?: Maybe<SettingYamlCustomContentFilterListInput>

  deploymentHostName?: Maybe<StringQueryOperatorInput>

  description?: Maybe<StringQueryOperatorInput>

  enabled?: Maybe<BooleanQueryOperatorInput>

  excludeDuplication?: Maybe<BooleanQueryOperatorInput>

  excludes?: Maybe<SettingYamlExcludeFilterListInput>

  facebook?: Maybe<StringQueryOperatorInput>

  fallbackUrl?: Maybe<StringQueryOperatorInput>

  faviconUrl?: Maybe<StringQueryOperatorInput>

  footer?: Maybe<StringQueryOperatorInput>

  gtags?: Maybe<StringQueryOperatorInput>

  header?: Maybe<StringQueryOperatorInput>

  headerImageAltText?: Maybe<StringQueryOperatorInput>

  headerImageLinkUrl?: Maybe<StringQueryOperatorInput>

  headerImageUrl?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  karakuriWebRootUrl?: Maybe<StringQueryOperatorInput>

  language?: Maybe<StringQueryOperatorInput>

  layoutLeft?: Maybe<LayoutFilterListInput>

  layoutMain?: Maybe<LayoutFilterListInput>

  layoutRight?: Maybe<LayoutFilterListInput>

  tagLayoutLeft?: Maybe<LayoutFilterListInput>

  tagLayoutMain?: Maybe<LayoutFilterListInput>

  tagLayoutRight?: Maybe<LayoutFilterListInput>

  answerLayoutLeft?: Maybe<LayoutFilterListInput>

  answerLayoutMain?: Maybe<LayoutFilterListInput>

  answerLayoutRight?: Maybe<LayoutFilterListInput>

  nrOfDisplay?: Maybe<IntQueryOperatorInput>

  pvTrackingId?: Maybe<StringQueryOperatorInput>

  pvViewId?: Maybe<StringQueryOperatorInput>

  questionnaire?: Maybe<SettingYamlQuestionnaireFilterInput>

  questionnaireTextNo?: Maybe<StringQueryOperatorInput>

  questionnaireTextYes?: Maybe<StringQueryOperatorInput>

  scripts?: Maybe<StringQueryOperatorInput>

  styles?: Maybe<StringQueryOperatorInput>

  title?: Maybe<StringQueryOperatorInput>

  twitter?: Maybe<StringQueryOperatorInput>

  url?: Maybe<StringQueryOperatorInput>

  tagCloud?: Maybe<BooleanQueryOperatorInput>

  categories?: Maybe<SettingYamlCategoriesFilterListInput>

  excludeFromFAQ?: Maybe<SettingYamlExcludeFromFaqFilterListInput>

  deploy?: Maybe<BooleanQueryOperatorInput>

  informations?: Maybe<SettingYamlInformationsFilterListInput>

  importants?: Maybe<SettingYamlImportantsFilterListInput>

  layout?: Maybe<StringQueryOperatorInput>

  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}
export interface AllSettingYamlQueryArgs {
  filter?: Maybe<SettingYamlFilterInput>

  sort?: Maybe<SettingYamlSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface TagYamlQueryArgs {
  id?: Maybe<StringQueryOperatorInput>

  answers?: Maybe<StringQueryOperatorInput>

  relations?: Maybe<StringQueryOperatorInput>

  ignore?: Maybe<BooleanQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>
}
export interface AllTagYamlQueryArgs {
  filter?: Maybe<TagYamlFilterInput>

  sort?: Maybe<TagYamlSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface SitePageQueryArgs {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  path?: Maybe<StringQueryOperatorInput>

  internalComponentName?: Maybe<StringQueryOperatorInput>

  component?: Maybe<StringQueryOperatorInput>

  componentChunkName?: Maybe<StringQueryOperatorInput>

  isCreatedByStatefulCreatePages?: Maybe<BooleanQueryOperatorInput>

  context?: Maybe<SitePageContextFilterInput>

  pluginCreator?: Maybe<SitePluginFilterInput>

  pluginCreatorId?: Maybe<StringQueryOperatorInput>

  componentPath?: Maybe<StringQueryOperatorInput>
}
export interface AllSitePageQueryArgs {
  filter?: Maybe<SitePageFilterInput>

  sort?: Maybe<SitePageSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface SitePluginQueryArgs {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  resolve?: Maybe<StringQueryOperatorInput>

  name?: Maybe<StringQueryOperatorInput>

  version?: Maybe<StringQueryOperatorInput>

  pluginOptions?: Maybe<SitePluginPluginOptionsFilterInput>

  nodeAPIs?: Maybe<StringQueryOperatorInput>

  browserAPIs?: Maybe<StringQueryOperatorInput>

  ssrAPIs?: Maybe<StringQueryOperatorInput>

  pluginFilepath?: Maybe<StringQueryOperatorInput>

  packageJson?: Maybe<SitePluginPackageJsonFilterInput>
}
export interface AllSitePluginQueryArgs {
  filter?: Maybe<SitePluginFilterInput>

  sort?: Maybe<SitePluginSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface SiteQueryArgs {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  siteMetadata?: Maybe<SiteSiteMetadataFilterInput>

  port?: Maybe<IntQueryOperatorInput>

  host?: Maybe<StringQueryOperatorInput>

  polyfill?: Maybe<BooleanQueryOperatorInput>

  pathPrefix?: Maybe<StringQueryOperatorInput>

  buildTime?: Maybe<DateQueryOperatorInput>
}
export interface AllSiteQueryArgs {
  filter?: Maybe<SiteFilterInput>

  sort?: Maybe<SiteSortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface DirectoryQueryArgs {
  id?: Maybe<StringQueryOperatorInput>

  parent?: Maybe<NodeFilterInput>

  children?: Maybe<NodeFilterListInput>

  internal?: Maybe<InternalFilterInput>

  sourceInstanceName?: Maybe<StringQueryOperatorInput>

  absolutePath?: Maybe<StringQueryOperatorInput>

  relativePath?: Maybe<StringQueryOperatorInput>

  extension?: Maybe<StringQueryOperatorInput>

  size?: Maybe<IntQueryOperatorInput>

  prettySize?: Maybe<StringQueryOperatorInput>

  modifiedTime?: Maybe<DateQueryOperatorInput>

  accessTime?: Maybe<DateQueryOperatorInput>

  changeTime?: Maybe<DateQueryOperatorInput>

  birthTime?: Maybe<DateQueryOperatorInput>

  root?: Maybe<StringQueryOperatorInput>

  dir?: Maybe<StringQueryOperatorInput>

  base?: Maybe<StringQueryOperatorInput>

  ext?: Maybe<StringQueryOperatorInput>

  name?: Maybe<StringQueryOperatorInput>

  relativeDirectory?: Maybe<StringQueryOperatorInput>

  dev?: Maybe<IntQueryOperatorInput>

  mode?: Maybe<IntQueryOperatorInput>

  nlink?: Maybe<IntQueryOperatorInput>

  uid?: Maybe<IntQueryOperatorInput>

  gid?: Maybe<IntQueryOperatorInput>

  rdev?: Maybe<IntQueryOperatorInput>

  blksize?: Maybe<IntQueryOperatorInput>

  ino?: Maybe<IntQueryOperatorInput>

  blocks?: Maybe<IntQueryOperatorInput>

  atimeMs?: Maybe<FloatQueryOperatorInput>

  mtimeMs?: Maybe<FloatQueryOperatorInput>

  ctimeMs?: Maybe<FloatQueryOperatorInput>

  birthtimeMs?: Maybe<FloatQueryOperatorInput>

  atime?: Maybe<DateQueryOperatorInput>

  mtime?: Maybe<DateQueryOperatorInput>

  ctime?: Maybe<DateQueryOperatorInput>

  birthtime?: Maybe<DateQueryOperatorInput>
}
export interface AllDirectoryQueryArgs {
  filter?: Maybe<DirectoryFilterInput>

  sort?: Maybe<DirectorySortInput>

  skip?: Maybe<number>

  limit?: Maybe<number>
}
export interface ModifiedTimeFileArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface AccessTimeFileArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface ChangeTimeFileArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface BirthTimeFileArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface AtimeFileArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface MtimeFileArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface CtimeFileArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface FixedImageSharpArgs {
  width?: Maybe<number>

  height?: Maybe<number>

  base64Width?: Maybe<number>

  jpegProgressive?: boolean

  pngCompressionSpeed?: number

  grayscale?: boolean

  duotone?: Maybe<DuotoneGradient>

  traceSVG?: Maybe<Potrace>

  quality?: Maybe<number>

  jpegQuality?: Maybe<number>

  pngQuality?: Maybe<number>

  webpQuality?: Maybe<number>

  toFormat?: ImageFormat

  toFormatBase64?: ImageFormat

  cropFocus?: ImageCropFocus

  fit?: ImageFit

  background?: string

  rotate?: number

  trim?: number
}
export interface ResolutionsImageSharpArgs {
  width?: Maybe<number>

  height?: Maybe<number>

  base64Width?: Maybe<number>

  jpegProgressive?: boolean

  pngCompressionSpeed?: number

  grayscale?: boolean

  duotone?: Maybe<DuotoneGradient>

  traceSVG?: Maybe<Potrace>

  quality?: Maybe<number>

  jpegQuality?: Maybe<number>

  pngQuality?: Maybe<number>

  webpQuality?: Maybe<number>

  toFormat?: ImageFormat

  toFormatBase64?: ImageFormat

  cropFocus?: ImageCropFocus

  fit?: ImageFit

  background?: string

  rotate?: number

  trim?: number
}
export interface FluidImageSharpArgs {
  maxWidth?: Maybe<number>

  maxHeight?: Maybe<number>

  base64Width?: Maybe<number>

  grayscale?: boolean

  jpegProgressive?: boolean

  pngCompressionSpeed?: number

  duotone?: Maybe<DuotoneGradient>

  traceSVG?: Maybe<Potrace>

  quality?: Maybe<number>

  jpegQuality?: Maybe<number>

  pngQuality?: Maybe<number>

  webpQuality?: Maybe<number>

  toFormat?: ImageFormat

  toFormatBase64?: ImageFormat

  cropFocus?: ImageCropFocus

  fit?: ImageFit

  background?: string

  rotate?: number

  trim?: number

  sizes?: string
  /** A list of image widths to be generated. Example: [ 200, 340, 520, 890 ] */
  srcSetBreakpoints?: (Maybe<number>)[]
}
export interface SizesImageSharpArgs {
  maxWidth?: Maybe<number>

  maxHeight?: Maybe<number>

  base64Width?: Maybe<number>

  grayscale?: boolean

  jpegProgressive?: boolean

  pngCompressionSpeed?: number

  duotone?: Maybe<DuotoneGradient>

  traceSVG?: Maybe<Potrace>

  quality?: Maybe<number>

  jpegQuality?: Maybe<number>

  pngQuality?: Maybe<number>

  webpQuality?: Maybe<number>

  toFormat?: ImageFormat

  toFormatBase64?: ImageFormat

  cropFocus?: ImageCropFocus

  fit?: ImageFit

  background?: string

  rotate?: number

  trim?: number

  sizes?: string
  /** A list of image widths to be generated. Example: [ 200, 340, 520, 890 ] */
  srcSetBreakpoints?: (Maybe<number>)[]
}
export interface ResizeImageSharpArgs {
  width?: Maybe<number>

  height?: Maybe<number>

  quality?: Maybe<number>

  jpegQuality?: Maybe<number>

  pngQuality?: Maybe<number>

  webpQuality?: Maybe<number>

  jpegProgressive?: boolean

  pngCompressionLevel?: number

  pngCompressionSpeed?: number

  grayscale?: boolean

  duotone?: Maybe<DuotoneGradient>

  base64?: boolean

  traceSVG?: Maybe<Potrace>

  toFormat?: ImageFormat

  cropFocus?: ImageCropFocus

  fit?: ImageFit

  background?: string

  rotate?: number

  trim?: number
}
export interface DistinctFileConnectionArgs {
  field: FileFieldsEnum
}
export interface GroupFileConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: FileFieldsEnum
}
export interface DistinctImageSharpConnectionArgs {
  field: ImageSharpFieldsEnum
}
export interface GroupImageSharpConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: ImageSharpFieldsEnum
}
export interface DistinctAnswerYamlConnectionArgs {
  field: AnswerYamlFieldsEnum
}
export interface GroupAnswerYamlConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: AnswerYamlFieldsEnum
}
export interface DistinctCategoryYamlConnectionArgs {
  field: CategoryYamlFieldsEnum
}
export interface GroupCategoryYamlConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: CategoryYamlFieldsEnum
}
export interface DistinctImportantYamlConnectionArgs {
  field: ImportantYamlFieldsEnum
}
export interface GroupImportantYamlConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: ImportantYamlFieldsEnum
}
export interface DistinctInformationYamlConnectionArgs {
  field: InformationYamlFieldsEnum
}
export interface GroupInformationYamlConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: InformationYamlFieldsEnum
}
export interface DistinctSettingYamlConnectionArgs {
  field: SettingYamlFieldsEnum
}
export interface GroupSettingYamlConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: SettingYamlFieldsEnum
}
export interface DistinctTagYamlConnectionArgs {
  field: TagYamlFieldsEnum
}
export interface GroupTagYamlConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: TagYamlFieldsEnum
}
export interface DistinctSitePageConnectionArgs {
  field: SitePageFieldsEnum
}
export interface GroupSitePageConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: SitePageFieldsEnum
}
export interface DistinctSitePluginConnectionArgs {
  field: SitePluginFieldsEnum
}
export interface GroupSitePluginConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: SitePluginFieldsEnum
}
export interface BuildTimeSiteArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface DistinctSiteConnectionArgs {
  field: SiteFieldsEnum
}
export interface GroupSiteConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: SiteFieldsEnum
}
export interface ModifiedTimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface AccessTimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface ChangeTimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface BirthTimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface AtimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface MtimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface CtimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface BirthtimeDirectoryArgs {
  /** Format the date using Moment.js' date tokens, e.g. `date(formatString: "YYYY MMMM DD")`. See https://momentjs.com/docs/#/displaying/format/ for documentation for different tokens. */
  formatString?: Maybe<string>
  /** Returns a string generated with Moment.js' `fromNow` function */
  fromNow?: Maybe<boolean>
  /** Returns the difference between this date and the current time. Defaults to "milliseconds" but you can also pass in as the measurement "years", "months", "weeks", "days", "hours", "minutes", and "seconds". */
  difference?: Maybe<string>
  /** Configures the locale Moment.js will use to format the date. */
  locale?: Maybe<string>
}
export interface DistinctDirectoryConnectionArgs {
  field: DirectoryFieldsEnum
}
export interface GroupDirectoryConnectionArgs {
  skip?: Maybe<number>

  limit?: Maybe<number>

  field: DirectoryFieldsEnum
}
